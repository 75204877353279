import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import '../../../src/Pages/StockChart/stockChartIndicator.css';



function AppBarComponent() {

  return (
    <AppBar style={{ backgroundColor: "#244832", }} position="static">
      <Grid item md={12} sm={12} xs={12}>
        <Grid container spacing={1}>
          <Grid item md={5} sm={6} xs={12}>
            <img src={require('../../assets/images/logo.png')} alt='logo'
              style={{ width: '200px', height: '45px', marginTop: 5, marginBottom: 5, backgroundColor: '#244832' }}></img>
          </Grid>
          <Grid item md={7} sm={6} xs={12} style={{marginTop:15}} className='navbarheader'>
            <Typography
              variant="h6"
              noWrap
              component="a"
            >
              Proprietary Quantitative Signal
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}
export default AppBarComponent;