import React from "react";
import { Routes, Route } from "react-router-dom";
import StockChartIndicator from "../Pages/StockChart/StockChartIndicator";
import MultiDataStock from "../Pages/StockChart/multiDataStock";
import AddScreenData from "../Pages/AddScreenData/AddScreenData";

//seting routes
const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<StockChartIndicator />} // initial route
        />
        <Route path="/v2" element={<MultiDataStock />} />
        <Route path="/AddData" element={<AddScreenData />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
