import React, { useEffect, useState } from "react";
//am5 stock chart components
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//main component two panels stock chart
const MultiDataStock: React.FC = () => {
  const [symName, setSymName] = useState("SPY");
  const [timeRange, setTimeRange] = useState("week");

  useEffect(() => {
    let root = am5.Root.new("chartdiv");
    root?._logo?.dispose();
    root.setThemes([am5themes_Animated.new(root)]);
    let stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {})
    );

    // Set global number format
    root.numberFormatter.set("numberFormat", "#,###.00");

    function addData(ticker: any, timeUnit: any) {
      // Create a main stock panel (chart)
      let mainPanel = stockChart.panels.push(
        am5stock.StockPanel.new(root, {
          wheelY: "zoomX",
          panX: true,
          panY: true,
        })
      );

      mainPanel.plotsContainer.set(
        "background",
        am5.Rectangle.new(root, {
          stroke: am5.color("#f2f2f2"),
          strokeOpacity: 1,
        })
      );
      mainPanel.panelControls.closeButton.set("forceHidden", true);

      // Create value axis
      let valueAxis = mainPanel.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            pan: "zoom",
            inside: false,
          }),
        })
      );

      let dateAxis = mainPanel.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          baseInterval: {
            timeUnit: timeUnit,
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
            marginRight: 10,
          }),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      dateAxis.get("renderer").labels.template.setAll({
        maxPosition: 0.99,
        minPosition: 0.01,
      });

      // Add series
      let valueSeries = mainPanel.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: ticker,
          clustered: true,
          valueXField: "Date",
          valueYField: "Close",
          highValueYField: "High",
          lowValueYField: "Low",
          openValueYField: "Open",
          calculateAggregates: true,
          xAxis: dateAxis,
          yAxis: valueAxis,
          legendValueText:
            "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
          legendRangeValueText: "",
          fill: am5.color("#BFA890"),
          stroke: am5.color("#BFA890"),
        })
      );

      valueSeries.columns.template.states.create("riseFromOpen", {
        fill: am5.color("#BFA890"),
        stroke: am5.color("#BFA890"),
      });
      valueSeries.columns.template.states.create("dropFromOpen", {
        fill: am5.color("#BFA890"),
        stroke: am5.color("#BFA890"),
      });

      valueSeries.columns.template.adapters.add(
        "fill",
        function (fill, target) {
          let dataItem: any = target.dataItem;
          if (dataItem?.dataContext.High > 70) {
            return am5.color("#B30100");
          } else if (dataItem?.dataContext.Low < 10) {
            return am5.color("#50B300");
          }
          return fill;
        }
      );

      // Set main value series
      stockChart.set("stockSeries", valueSeries);

      // Add a stock legend
      let valueLegend = mainPanel.plotContainer.children.push(
        am5stock.StockLegend.new(root, {
          stockChart: stockChart,
        })
      );

      // Create volume axis
      let volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
        inside: true,
      });

      volumeAxisRenderer.labels.template.set("forceHidden", true);
      volumeAxisRenderer.grid.template.set("forceHidden", true);

      let volumeValueAxis = mainPanel.yAxes.push(
        am5xy.ValueAxis.new(root, {
          numberFormat: "#.#a",
          height: am5.percent(20),
          y: am5.percent(100),
          centerY: am5.percent(100),
          renderer: volumeAxisRenderer,
        })
      );

      // Add series
      let volumeSeries = mainPanel.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Volume",
          clustered: false,
          valueXField: "Date",
          valueYField: "Volume",
          xAxis: dateAxis,
          yAxis: volumeValueAxis,
          legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
        })
      );

      volumeSeries.columns.template.adapters.add(
        "fill",
        function (fill, target) {
          let dataItem: any = target.dataItem;
          if (dataItem) {
            return stockChart.getVolumeColor(dataItem);
          }
          return fill;
        }
      );

      // Set main series
      stockChart.set("volumeSeries", volumeSeries);
      valueLegend.data.setAll([valueSeries, volumeSeries]);

      var scrollbar = mainPanel.set(
        "scrollbarX",
        am5xy.XYChartScrollbar.new(root, {
          orientation: "horizontal",
          height: 50,
          forceHidden: true,
        })
      );
      stockChart.toolsContainer.children.push(scrollbar);

      var sbDateAxis = scrollbar.chart.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          baseInterval: {
            timeUnit: timeUnit,
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );

      var sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      var sbSeries = scrollbar.chart.series.push(
        am5xy.LineSeries.new(root, {
          valueYField: "Close",
          valueXField: "Date",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      );

      sbSeries.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3,
      });
      // Add cursor(s)
      mainPanel.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          yAxis: valueAxis,
          xAxis: dateAxis,
          snapToSeries: [valueSeries],
          snapToSeriesBy: "y!",
        })
      );

      // Function that dynamically loads data
      function loadData(sym: any, series: any, granularity: any) {
        // Load external data
        //https://devmitresource.s3.ap-south-1.amazonaws.com/spy-month.json
        am5.net
          .load(
            "https://financialindicator.s3.ap-south-1.amazonaws.com/" +
              sym +
              "/" +
              granularity +
              ".json"
          )
          .then(function (result: any) {
            let data = am5.JSONParser.parse(result.response, {
              reverse: true,
            });

            // Process data (convert dates and values)
            let processor = am5.DataProcessor.new(root, {
              dateFields: ["Date"],
              dateFormat: "yyyy-MM-dd",
              numericFields: [
                "Open",
                "High",
                "Low",
                "Close",
                "Adj Close",
                "Volume",
              ],
            });
            processor.processMany(data);

            // Set data
            am5.array.each(series, function (item: any) {
              //console.log("check==>",item);
              item.data.setAll(data);
            });
          });
      }

      // Load initial data for the first series
      let currentGranularity = timeUnit;
      let dataSYM = ticker;

      loadData(dataSYM, [valueSeries, volumeSeries], currentGranularity);
      // Set up main indices selector
      if (ticker !== "PCP") {
        let mainSeriesControl = am5stock.DropdownListControl.new(root, {
          stockChart: stockChart,
          name: valueSeries.get("name"),
          icon: am5stock.StockIcons.getIcon("Candlestick Series"),
          fixedLabel: true,
          searchable: true,
          searchCallback: function (query) {
            let mainSeries = stockChart.get("stockSeries");
            let mainSeriesID = mainSeries ? mainSeries.get("name") : "";
            let list = getTicker(query);
            am5.array.each(list, function (item: any) {
              if (item.id === mainSeriesID) {
                item.disabled = true;
              }
            });
            return list;
          },
        });

        mainSeriesControl.events.on("selected", function (ev: any) {
          let valueSeries = stockChart.get("stockSeries");
          let volumeSeries = stockChart.get("volumeSeries");
          setSymName(ev.item.subLabel);
          mainSeriesControl.set("name", ev.item.subLabel);
          valueSeries?.set("name", ev.item.subLabel);
          loadData(
            ev.item.subLabel,
            [valueSeries, volumeSeries],
            currentGranularity
          );
        });

        function getTicker(search: any) {
          if (search === "") {
            return [];
          }
          search = search.toLowerCase();

          let tickers = [
            {
              _id: "654c606f28cdb9cbab70a962",
              label: "SPDR S&P",
              subLabel: "SPY",
              id: "SPY",
              __v: 0,
            },
            {
              _id: "654c606f28cdb9cbab70a963",
              label: "Invesco QQQ",
              subLabel: "QQQ",
              id: "QQQ",
              __v: 0,
            },
            {
              _id: "654c606f28cdb9cbab70a964",
              label: "ProShares UltraPro QQQ",
              subLabel: "TQQQ",
              id: "TQQQ",
              __v: 0,
            },
            {
              _id: "654c606f28cdb9cbab70a965",
              label: "SPXL",
              subLabel: "SPXL",
              id: "SPXL",
              __v: 0,
            },
            {
              _id: "654c606f28cdb9cbab70a966",
              label: "PCP",
              subLabel: "PCP",
              id: "PCP",
              __v: 0,
            },
          ];
          return tickers.filter(function (item: any) {
            return (
              item.label.toLowerCase().match(search) ||
              item.subLabel.toLowerCase().match(search)
            );
          });
        }

        // Set up series type switcher
        let seriesSwitcher = am5stock.SeriesTypeControl.new(root, {
          stockChart: stockChart,
        });

        seriesSwitcher.events.on("selected", function (ev: any) {
          setSeriesType(ev.item.id);
        });

        function getNewSettings(series: any) {
          let newSettings: any = [];
          am5.array.each(
            [
              "name",
              "valueYField",
              "highValueYField",
              "lowValueYField",
              "openValueYField",
              "calculateAggregates",
              "valueXField",
              "xAxis",
              "yAxis",
              "legendValueText",
              "stroke",
              "fill",
            ],
            function (setting) {
              newSettings[setting] = series.get(setting);
            }
          );
          return newSettings;
        }

        function setSeriesType(seriesType: any) {
          // Get current series and its settings
          let currentSeries: any = stockChart.get("stockSeries");
          let newSettings = getNewSettings(currentSeries);

          // Remove previous series
          let data = currentSeries.data.values;
          mainPanel.series.removeValue(currentSeries);

          // Create new series
          let series: any = {};
          switch (seriesType) {
            case "line":
              series = mainPanel.series.push(
                am5xy.LineSeries.new(root, newSettings)
              );
              break;
            case "candlestick":
            case "procandlestick":
              newSettings.clustered = false;
              series = mainPanel.series.push(
                am5xy.CandlestickSeries.new(root, newSettings)
              );
              if (seriesType === "procandlestick") {
                series.columns.template.get("themeTags").push("pro");
              }
              break;
            case "ohlc":
              newSettings.clustered = false;
              series = mainPanel.series.push(
                am5xy.OHLCSeries.new(root, newSettings)
              );
              break;
          }

          // Set new series as stockSeries
          if (series) {
            valueLegend.data.removeValue(currentSeries);
            series.data.setAll(data);
            stockChart.set("stockSeries", series);
            let cursor = mainPanel.get("cursor");
            if (cursor) {
              cursor.set("snapToSeries", [series]);
            }
            valueLegend.data.insertIndex(0, series);
          }
        }

        var intervalSwitcher = am5stock.IntervalControl.new(root, {
          stockChart: stockChart,
          items: [
            {
              id: "1 week",
              label: "Weekly",
              interval: { timeUnit: "week", count: 1 },
            },
            {
              id: "1 month",
              label: "Monthly",
              interval: { timeUnit: "month", count: 1 },
            },
          ],
        });

        intervalSwitcher.events.on("selected", function (ev: any) {
          // Determine selected granularity
          setTimeRange(ev.item.interval.timeUnit);
          currentGranularity = ev.item.interval.timeUnit;

          // Get series
          var valueSeries = stockChart.get("stockSeries");
          var volumeSeries = stockChart.get("volumeSeries");

          // Set up zoomout
          valueSeries?.events.once("datavalidated", function () {
            mainPanel.zoomOut();
          });

          // Load data for all series (main series + comparisons)
          var promises = [];
          promises.push(
            loadData(dataSYM, [valueSeries, volumeSeries], timeRange)
          );
          am5.array.each(
            stockChart.getPrivate("comparedSeries", []),
            function (series) {
              promises.push(loadData(series.get("name"), [series], timeRange));
            }
          );

          // Once data loading is done, set `baseInterval` on the DateAxis
          Promise.all(promises).then(function () {
            dateAxis.set("baseInterval", ev.item.interval);
          });
        });

        let periodSelector = am5stock.PeriodSelector.new(root, {
          stockChart: stockChart,
        });

        valueSeries.events.once("datavalidated", function (ev: any) {
          periodSelector.selectPeriod({
            timeUnit: "year",
            count: 1,
            name: "1Y",
          });
        });

        // Stock toolbar
        let root2 = document.getElementById("chartcontrols")!;
        if (!root2) {
          am5stock.StockToolbar.new(root, {
            container: root2,
            stockChart: stockChart,
            controls: [
              mainSeriesControl,
              am5stock.IndicatorControl.new(root, {
                stockChart: stockChart,
                legend: valueLegend,
              }),
              am5stock.PeriodSelector.new(root, {
                stockChart: stockChart,
                description: "Time Range",
                periods: [
                  { timeUnit: "month", count: 3, name: "3M" },
                  { timeUnit: "month", count: 6, name: "6M" },
                  { timeUnit: "year", count: 1, name: "1Y" },
                  { timeUnit: "year", count: 2, name: "2Y" },
                  { timeUnit: "year", count: 5, name: "5Y" },
                  { timeUnit: "max", name: "Max" },
                ],
              }),
              intervalSwitcher,
              am5stock.DrawingControl.new(root, {
                stockChart: stockChart,
              }),
              am5stock.ResetControl.new(root, {
                stockChart: stockChart,
              }),
              am5stock.SettingsControl.new(root, {
                stockChart: stockChart,
              }),
            ],
          });
        }
      }
    }

    //calling two symbols data functions
    addData("PCP", timeRange);
    addData(symName, timeRange);

    return () => {
      root.dispose();
    };
  }, [symName, timeRange]);

  return (
    <div>
      {/* main stock ui component */}
      <div id="chartcontrols" style={{ marginLeft: 10 }}></div>
      <div id="chartdiv" style={{ height: "500px", marginTop: -5 }}></div>
      {/* end */}
    </div>
  );
};
export default MultiDataStock;
