import React, { useState, useEffect } from "react";
//components material ui lib
import "../../Pages/StockChart/stockChartIndicator.css";
import { Grid, Typography } from "@mui/material";

import MaterialFormComponent from "../../components/FormComponent/MaterialFormComponet";
import PCPFileUpload from "./PCPFileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

const AddScreenData = (props: any) => {
  //state variables
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [open, setOpen] = React.useState(false); // open modal state
  const [showData, setShowData] = useState(false); // default not showing data condition state
  const [password, setPassword] = useState(""); // input filed of password state
  const [visible, setVisible] = useState(""); // valadations displayed state

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{ fontSize: "0.9rem", color: "#0DA718" }}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    let pass = localStorage.getItem("user_password");
    let passTime: any = localStorage.getItem("date");
    if (pass === null && passTime === null) {
      setOpen(true);
    } else {
      setShowData(true);
      //localStorage.clear()
    }

    const intervalId = setInterval(() => {
      let tim = new Date().getTime();
      //console.log('min---->232342',tim,"and",passTime);

      let d = Math.floor((tim - passTime) / 1000);
      //console.log("sub===>",d)
      if (d > 10800) {
        localStorage.clear();
        setOpen(true);
        setShowData(false);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleClose = () => {
    if (password === "") {
      setVisible("empty");
    } else if (password !== "P99C@422853n$") {
      setVisible("wrong");
    } else if (password === "P99C@422853n$") {
      setShowData(true);
      setOpen(false);
      setVisible("");
      setPassword("");
      //set password and login time in local stroage
      localStorage.setItem("user_password", "P99C@422853n$");
      let timeD = new Date();
      ///console.log("check===>1",timeD,"and","and time",timeD.getTime())
      localStorage.setItem("date", timeD.getTime().toString());
    }
  };

  return (
    <div>
      <div>
        <Dialog open={open}>
          <DialogTitle>Password required</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This site is password protected. Please enter a password.
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  document.getElementById("myBtn")?.click();
                }
              }}
            />
            {visible === "empty" ? (
              <Typography color={"red"} fontSize={14}>
                Password required*
              </Typography>
            ) : null}
            {visible === "wrong" ? (
              <Typography color={"red"} fontSize={14}>
                Please enter correct password
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button id="myBtn" onClick={handleClose} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        style={{
          backgroundColor: "#0DA718",
          paddingTop: "20px",
          paddingBottom: "20px",
          position: "fixed",
          top: 58,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "auto",
        }}
      >
        <div>
          <Grid
            container
            spacing={2}
            sx={{
              border: "2px solid #ECECEC",
              width: "80%",
              margin: "auto",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              backgroundColor: "white",
            }}
          >
            <div>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{
                    // backgroundColor: "#bebab3",
                    color: "#0DA718 !important",
                  }}
                >
                  <Typography> ADD PCP DATA</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MaterialFormComponent />
                </AccordionDetails>
              </Accordion>
              {/* <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  sx={{
                    // backgroundColor: "#0DA718",
                    color: "#0DA718 !important",
                  }}
                >
                  <Typography>UPLOAD PCP DATA CSV FILE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PCPFileUpload />
                </AccordionDetails>
              </Accordion> */}
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AddScreenData;
