import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Backdrop } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
//components material ui lib
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  InputAdornment,
} from "@mui/material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "../../Pages/StockChart/stockChartIndicator.css";

interface StockData {
  Open: string;
  Close: string;
  High: string; // Assuming 'high' property is lowercase in your data
  Low: string;
  // Add other properties if needed
}

const MaterialFormComponent = (props: any) => {
  //state variables
  const [selectChart, setSelectChart] = useState("PCP");
  const [timePeriod, setTimePeriod] = useState("day");
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [timePicker, setTimePicker] = React.useState<dayjs.Dayjs | null>(
    dayjs()
  );
  const [open, setOpen] = useState("");
  const [close, setClose] = useState("");
  const [high, setHigh] = useState("");
  const [low, setLow] = useState("");
  const [newArray, setNewArray] = useState<StockData | null>(null);
  const [openValidation, setOpenValidation] = React.useState(false); // open modal state
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSelectChart = (e: any) => {
    const selectedPcp = e.target.value;
    setSelectChart(selectedPcp);

    // Reset other fields when chart is changed
    setTimePeriod("");
    setDate(null);
    setTimePicker(null);
    setOpen("");
    setClose("");
    setHigh("");
    setLow("");
  };

  //time period dropdown
  const handleTimePeriod = (e: any) => {
    const selectedTimePeriod = e.target.value;
    setTimePeriod(selectedTimePeriod);
    setDate(null);
  };

  const handleDate = (e: any) => {
    const selectedDate = dayjs(e.target.value);
    setDate(selectedDate);
    setTimePicker(null);
    setOpen("");
    setClose("");
    setHigh("");
    setLow("");
  };

  const handleOpen = (e: any) => {
    setOpen(e.target.value);
  };

  const handleClose = (e: any) => {
    setClose(e.target.value);
  };

  const handleHigh = (e: any) => {
    setHigh(e.target.value);
  };

  const handleLow = (e: any) => {
    setLow(e.target.value);
  };

  const handleTimeBlur = async (e: any) => {
    const selectedDate = dayjs(e.target.value);
    if (timePeriod !== "hour") {
      setLoading(true);
      setTimeout(async () => {
        const formData = {
          pcp: selectChart,
          time: timePeriod,
          date: selectedDate ? selectedDate.format("D/MM/YYYY") : null,
          timeDate: timePicker ? timePicker.format("h:mm:ss A") : "12:00:00 AM",
          // 1/17/2018 12:00:00 AM
        };
        try {
          // Make a POST request with the FormDatapost
          const response = await axios.post(
            "https://2ooy77ef5d.execute-api.us-west-2.amazonaws.com/test/test_post",
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const bodyObject = JSON.parse(response.data.body);
          if (bodyObject.status === false) {
            setNewArray(bodyObject.data[0]);
            setOpenValidation(true);
          } else {
            setOpen("");
            setClose("");
            setHigh("");
            setLow("");
            setOpenValidation(false);
          }
          // Handle the response as needed
          // console.log("API Response:", response.data);
        } catch (error) {
          // Handle errors
          console.error("API Error:", error);
        } finally {
          setLoading(false);
        }
      }, 100);
    }
  };

  const handleTimeOnly = async (e: any) => {
    setOpen("");
    setClose("");
    setHigh("");
    setLow("");
    let time = e;
    // if (timePeriod != 'hour') {
    setLoading(true);
    setTimeout(async () => {
      const formData = {
        pcp: selectChart,
        time: timePeriod,
        date: date ? date.format("D/MM/YYYY") : null,
        timeDate: time ? time.format("h:mm:ss A") : "00:00:00",
      };
      try {
        // Make a POST request with the FormData
        const response = await axios.post(
          "https://2ooy77ef5d.execute-api.us-west-2.amazonaws.com/test/test_post",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const bodyObject = JSON.parse(response.data.body);
        if (bodyObject.status === false) {
          setNewArray(bodyObject.data[0]);
          setOpenValidation(true);
        } else {
          setOpen("");
          setClose("");
          setHigh("");
          setLow("");
          setOpenValidation(false);
        }
        // Handle the response as needed
        // console.log("API Response:", response.data);
      } catch (error) {
        // Handle errors
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    }, 100);
    // }
  };

  const handleValiYesClose = () => {
    let data = newArray;

    if (data) {
      const Open = data.Open;
      setOpen(Open);
      const Close = data.Close;
      setClose(Close);
      setHigh(data.High);
      setLow(data.Low);
    }
    setOpenValidation(false);
  };

  const handleValiNoClose = () => {
    setOpenValidation(false);
    setSelectChart("");
    setTimePeriod("");
    setDate(null);
    setTimePicker(null);
    setOpen("");
    setClose("");
    setHigh("");
    setLow("");
  };

  //disable purpose
  const isChartSelected = selectChart !== "";
  const isTimePeriodSelected = timePeriod !== "";
  const isDateSelected = date;
  const isTimePickerSelected = timePicker;
  const isOpenSelected = open !== "";
  const isCloseSelected = close !== "";
  const isHighSelected = high !== "";
  const isLowSelected = low !== "";

  // when time range - hourly -- need time picker
  const isHourlySelected = timePeriod === "hour" && isDateSelected;

  // submit btn disable untill all values are enetred
  const isSubmitDisabled =
    !isChartSelected ||
    !isTimePeriodSelected ||
    !isDateSelected ||
    (isHourlySelected && !isTimePickerSelected) ||
    !isOpenSelected ||
    !isCloseSelected ||
    !isHighSelected ||
    !isLowSelected;
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formData = {
        pcp: selectChart,
        time: timePeriod,
        date: date ? date.format("D/MM/YYYY") : null,
        timeDate: timePicker ? timePicker.format("h:mm:ss A") : "00:00:00",
        open: open,
        close: close,
        high: high,
        low: low,
      };

      // Make a POST request to your backend endpoint
      await axios.post(
        "https://2ooy77ef5d.execute-api.us-west-2.amazonaws.com/test/test",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSnackbarMessage("Data submitted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Handle the response if needed
      // console.log("API Response:", response.data);
      setLoading(false);

      // Reset form fields

      // setSelectChart("");
      // setTimePeriod("");

      setDate(null);
      setTimePicker(null);
      setOpen("");
      setClose("");
      setHigh("");
      setLow("");
    } catch (error) {
      // Handle errors
      console.error("API Error:", error);

      // You may want to provide feedback to the user about the error
    } finally {
      setLoading(false);
    }
  };

  //all pcp symbols like tickers data
  const pcpData = [
    { id: 1, label: "PCP", value: "PCP" },
    // { id: 2, label: "PCP100", value: "PCP100" },
    { id: 3, label: "PCP103", value: "PCP103" },
    { id: 4, label: "PCP106", value: "PCP106" },
    { id: 5, label: "PCP107", value: "PCP107" },
    { id: 6, label: "PCP111", value: "PCP111" },
    { id: 7, label: "PCP111", value: "PCP112" },
    { id: 8, label: "PCP122", value: "PCP122" },
  ];

  //time range of symbols data
  const timeRange = [
    { id: 1.1, value: "hour", label: "Hour" },
    { id: 1.2, value: "day", label: "Day" },
    { id: 1.3, value: "week", label: "Week" },
    { id: 1.4, value: "month", label: "Month" },
    { id: 1.5, value: "quarter", label: "Quarter" },
    { id: 1.6, value: "year", label: "Year" },
  ];

  const shouldDisableDate = (
    day: dayjs.Dayjs,
    selectedTimeRange: string | null
  ) => {
    if (selectedTimeRange === "week") {
      return day.day() !== 1; // Disable all days except Monday
    } else if (selectedTimeRange === "month") {
      return day.date() !== 1; // Enable only the 15th of each month
    } else if (selectedTimeRange === "quarter") {
      const quarterStartMonths = [2, 5, 8, 11]; // January, April, July, November
      return !quarterStartMonths.includes(day.month());
    } else if (selectedTimeRange === "year") {
      return day.month() !== 0; //January month is enable to select
    }
    // Disable Saturdays (day.getDay() === 6) and Sundays (day.getDay() === 0)
    return day.day() === 6 || day.day() === 0;
  };

  return (
    <div
    // style={{
    //   backgroundColor: "#0DA718",
    //   paddingTop: "20px",
    //   paddingBottom: "20px",
    //   position: "fixed",
    //   top: 58,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   overflow: "auto",
    // }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust duration as needed
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <div>
        <Dialog open={openValidation}>
          <DialogTitle>
            {selectChart} - {timePeriod}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This Record Alredy Exists, are you sure want change values ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="myBtn" onClick={handleValiYesClose}>
              Yes
            </Button>
            <Button id="myBtn" onClick={handleValiNoClose}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
            ADD PCP DATA
          </h3>
        </Grid>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid item xs={12} sm={5} md={4}>
          <FormControl fullWidth focused={false}>
            <Select
              name="pcp"
              value={selectChart}
              onChange={handleSelectChart}
              displayEmpty
              sx={{ marginLeft: "-10px" }}
            >
              <MenuItem disabled value="" sx={{ display: "none" }}>
                PCP
              </MenuItem>
              {pcpData.map((pcp) => (
                <MenuItem
                  key={pcp.value}
                  value={pcp.value}
                  sx={{
                    "&:hover": { backgroundColor: "green", color: "white" },
                    "&.Mui-selected, &.Mui-selected:hover": {
                      backgroundColor: "green",
                      color: "white",
                    },
                  }}
                >
                  {pcp.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={3}>
          <FormControl fullWidth focused={false} disabled={!isChartSelected}>
            <Select
              name="timeRange"
              value={timePeriod}
              onChange={handleTimePeriod}
              displayEmpty
              sx={{ marginLeft: "-10px" }}
            >
              <MenuItem disabled value="" sx={{ display: "none" }}>
                Day
              </MenuItem>
              {selectChart === "PCP"
                ? // Render all timeRange options
                  timeRange.map((time) => (
                    <MenuItem
                      key={time.value}
                      value={time.value}
                      sx={{
                        "&:hover": { backgroundColor: "green", color: "white" },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          backgroundColor: "green",
                          color: "white",
                        },
                      }}
                    >
                      {time.label}
                    </MenuItem>
                  ))
                : // Render only 'Daily' and 'Weekly' options
                  timeRange
                    // .filter((time) => ["Daily", "Weekly"].includes(time.value))
                    .map((time) => (
                      <MenuItem
                        key={time.value}
                        value={time.value}
                        sx={{
                          "&:hover": {
                            backgroundColor: "green",
                            color: "white",
                          },
                          "&.Mui-selected, &.Mui-selected:hover": {
                            backgroundColor: "green",
                            color: "white",
                          },
                        }}
                      >
                        {time.label}
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5.2}
          md={3}
          className="datetime-picker"
          style={{ marginTop: -8 }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "MobileDatePicker"]}>
              <DemoItem>
                <MobileDatePicker
                  label="Date Picker"
                  value={date}
                  onChange={(newValuee: any) =>
                    handleDate({ target: { value: newValuee } })
                  }
                  disabled={!isTimePeriodSelected}
                  closeOnSelect={true}
                  onAccept={(newValuee: any) =>
                    handleTimeBlur({ target: { value: newValuee } })
                  }
                  // shouldDisableDate={shouldDisableDate}
                  shouldDisableDate={(day: dayjs.Dayjs) =>
                    shouldDisableDate(day, timePeriod)
                  }
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment
                            sx={{ color: "#979797" }}
                            position="end"
                          >
                            <CalendarMonthIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>

        {/* Render time picker only when Hourly is selected */}
        {isHourlySelected && (
          <Grid
            item
            xs={12}
            sm={5.2}
            md={2}
            className="time-picker"
            style={{ marginTop: -8 }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["MobileTimePicker"]}>
                <DemoItem>
                  <MobileTimePicker
                    views={["hours"]}
                    // value={timePicker}
                    value={
                      timePicker
                        ? dayjs(timePicker.format("HH:mm"), "HH:mm")
                        : null
                    }
                    label="Time"
                    closeOnSelect={true}
                    onAccept={(newValue: any) => handleTimeOnly(newValue)}
                    ampm={false} // Set ampm to false for 24-hour format
                    onChange={(newValue: any) => setTimePicker(newValue)}
                    minTime={dayjs().hour(9).minute(0)}
                    maxTime={dayjs().hour(16).minute(0)}
                    shouldDisableTime={(time: dayjs.Dayjs) => time.hour() === 9}
                    slotProps={{
                      actionBar: {
                        actions: ["cancel", "accept"],
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        )}

        <Grid
          container
          spacing={2}
          sx={{ marginTop: "10px", marginLeft: "1px" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="open"
              name="open"
              label="Open"
              type="number"
              variant="standard"
              value={open}
              onChange={handleOpen}
              focused={false}
              fullWidth
              disabled={!isDateSelected}
              sx={{
                "& input[type=number]": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="high"
              name="high"
              label="High"
              type="number"
              variant="standard"
              value={high}
              onChange={handleHigh}
              focused={false}
              fullWidth
              disabled={!isDateSelected}
              sx={{
                "& input[type=number]": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="low"
              name="low"
              label="Low"
              type="number"
              variant="standard"
              value={low}
              onChange={handleLow}
              focused={false}
              fullWidth
              disabled={!isDateSelected}
              sx={{
                "& input[type=number]": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              id="close"
              name="close"
              label="Close"
              type="number"
              variant="standard"
              value={close}
              onChange={handleClose}
              focused={false}
              fullWidth
              disabled={!isDateSelected}
              sx={{
                "& input[type=number]": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            sx={{
              marginTop: "20px",
              float: "right",
            }}
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            href="/"
            variant="outlined"
            color="success"
            className="Dashboard"
            sx={{
              marginTop: "20px",
              float: "left",
            }}
          >
            {" "}
            Back To DashBoard
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MaterialFormComponent;
