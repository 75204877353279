import React from 'react';
import AppBarComponent from './components/HeaderComponent/AppBarComponent';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRouter';
 
const App: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <AppBarComponent />
        <div style={{ marginTop: 20 }}>
          <AppRoutes />
        </div>
      </BrowserRouter>
    </div>
  );
};
 
export default App;