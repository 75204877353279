import React, { useEffect, useState } from "react";
// @material ui libraries components
import { Grid, Typography } from "@mui/material";
import { Select, MenuItem, FormControl, ListItemIcon } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";

// am5 stock chart import libraries
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// chart load effect
import hljs from "highlight.js";
// css file imported
import "./stockChartIndicator.css";
import axios from "axios";

let a = am5xy.OHLCSeries;
let b = am5xy.CandlestickSeries;
let c = am5xy.LineSeries;

//Series Switcher data
const icons = [
  {
    id: "line",
    label: "Line",
    icon: "Line Series",
  },
  {
    id: "candlestick",
    label: "Candles",
    icon: "Candlestick Series",
  },
  {
    id: "procandlestick",
    label: "Hollow Candles",
    icon: "Pro Candlestick Series",
  },
  {
    id: "ohlc",
    label: "Sticks",
    icon: "OHLC Series",
  },
];

//initial data loading index
const initialData = {
  id: 0,
  mName: "Historical",
  status: 1,
  mData: {
    liveSymbolName: "SPY",
    pcpSymbolName: "PCP",
    saveSeriesTypeA: {
      id: "line",
      label: "Line",
      icon: "Line Series",
    },
    saveSeriesTypeB: {
      id: "candlestick",
      label: "Candles",
      icon: "Candlestick Series",
    },
    timeUnit: "day",
    saveInterval: { timeUnit: "year", count: 1, name: "1Y" },
    savePeriod: {
      id: "1 day",
      label: "Daily",
      interval: { timeUnit: "day", count: 1 },
    },
    saveIndicators: [],
    saveDrawings: [],
  },
};

// Custom Time period stock chart data
const range = [
  { id: "1 hour", label: "Hourly", interval: { timeUnit: "hour", count: 1 } },
  { id: "1 day", label: "Daily", interval: { timeUnit: "day", count: 1 } },
  { id: "1 week", label: "Weekly", interval: { timeUnit: "week", count: 1 } },
  {
    id: "1 month",
    label: "Monthly",
    interval: { timeUnit: "month", count: 1 },
  },
  {
    id: "2 year",
    label: "Quarterly",
    interval: { timeUnit: "year", count: 1 },
  },
  { id: "1 year", label: "Yearly", interval: { timeUnit: "year", count: 1 } },
];

//Custom time interval stocl chart data
const period = [
  { timeUnit: "day", count: 15, name: "15D" },
  { timeUnit: "month", count: 1, name: "1M" },
  { timeUnit: "month", count: 3, name: "3M" },
  { timeUnit: "month", count: 6, name: "6M" },
  { timeUnit: "year", count: 1, name: "1Y" },
  { timeUnit: "year", count: 2, name: "2Y" },
  { timeUnit: "year", count: 5, name: "5Y" },
  { timeUnit: "year", count: 10, name: "10Y" },
  { timeUnit: "year", count: 15, name: "15Y" },
  { timeUnit: "year", count: 20, name: "20Y" },
  { timeUnit: "max", name: "Max" },
];

interface DashboardItem {
  id: number;
  mName: string; // Add the 'name' property here
}

// default time interval values time period in stock chart
let monthDefault = { timeUnit: "year", count: 2, name: "2Y" };
let weekDefault = { timeUnit: "year", count: 1, name: "1Y" };
let dayDefault = { timeUnit: "year", count: 1, name: "1Y" };
let quarterDefault = { timeUnit: "year", count: 5, name: "5Y" };
let hourDefault = { timeUnit: "month", count: 1, name: "1M" };

//main component in single panel chart with typescript start
const StockChartIndicator: React.FC = () => {
  const updateValues: any = localStorage.getItem("update_data");
  const userData =
    JSON.parse(updateValues) !== null ? JSON.parse(updateValues) : initialData;

  const [getNames, setGetNames] = useState<DashboardItem[]>([]); // dropdown list
  //local state's
  const [symName, setSymName] = useState(userData?.mData?.liveSymbolName); // symbol name state
  const [pcpName, setPcpName] = useState(userData?.mData?.pcpSymbolName); // client symbol name state
  const [timeRange, setTimeRange] = useState(userData?.mData?.timeUnit); // time unit state
  const [selectedPeriod, setSelectedPeriod] = useState({
    timeUnit: userData?.mData?.saveInterval.timeUnit,
    count: userData?.mData?.saveInterval?.count,
    name: userData?.mData?.saveInterval?.name,
  }); // time interval state
  const [intervalControl, setIntervalControl] = useState({
    id: userData?.mData?.savePeriod?.id,
    label: userData?.mData?.savePeriod?.label,
    interval: {
      timeUnit: userData?.mData?.savePeriod?.interval?.timeUnit,
      count: userData?.mData?.savePeriod?.interval?.count,
    },
  }); // time period state
  const [showData, setShowData] = useState(false); // default not showing data condition state
  const [symSeriesId, setSymSeriesId] = useState({
    id: userData?.mData?.saveSeriesTypeA?.id,
    label: userData?.mData?.saveSeriesTypeA?.label,
    icon: userData?.mData?.saveSeriesTypeA?.icon,
  });
  let d: any =
    symSeriesId.id === "line" ? c : symSeriesId.id === "ohlc" ? a : b;
  const [pcpSeriesId, setPcpSeriesId] = useState({
    id: userData?.mData?.saveSeriesTypeB?.id,
    label: userData?.mData?.saveSeriesTypeB?.label,
    icon: userData?.mData?.saveSeriesTypeB?.icon,
  });
  let f: any =
    pcpSeriesId.id === "line" ? c : pcpSeriesId.id === "ohlc" ? a : b;
  const [saveIndicators, setSaveIndicators] = useState(
    userData?.mData?.saveIndicators
  );
  const [saveDraws, setSaveDraws] = useState(userData?.mData?.saveDrawings);
  const [getDropValues, setDropValues] = useState({
    id: userData?.id,
    mName: userData?.mName,
  });

  const [applyLayout, setApplyLayout] = useState({
    id: 0,
    mName: "Unamed",
  });
  const [isValid, setIsValid] = useState(true);
  const [multiNameOpen, setMultiNameOpen] = useState(false);
  const [open, setOpen] = React.useState(false); // open modal state
  const [password, setPassword] = useState(""); // input filed of password state
  const [visible, setVisible] = useState(""); // valadations displayed state
  const [isDrawActive, setIsDrawActive] = useState(false);
  const [isPCP, setIsPCP] = useState("ON");
  const [isSPY, setIsSPY] = useState("ON");
  const [drawPosition, setDrawPosition] = useState(false);
  //initial Global State
  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dropOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [toastOpen, setToastOpen] = useState(false);
  const [msgStatus, setMsgStatus] = useState("");
  let nM = getDropValues?.id !== 0 ? getDropValues.mName : "Historical";
  const [mdName, setMdName] = useState(nM); //ini

  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
  };

  // initial calling
  let getUrls = "https://ceiak6b9aa.execute-api.us-west-2.amazonaws.com/test/getdata";
  //let getUrls =
   // "https://1rd6bf770j.execute-api.us-west-2.amazonaws.com/test/get_dev";

  const getDropCall = () => {
    fetch(getUrls, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setGetNames(data?.data);
      })
      .catch((error) => console.log(error));
  };
  //initial api call
  useEffect(() => {
    getDropCall();
  }, []);


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSaveData = () => {
    setMultiNameOpen(true);
  };

  const handleMultiClose = () => {
    setMultiNameOpen(false);
  };

  //dialog submit btn calling function
  const handleClose = () => {
    if (password === "") {
      setVisible("empty");
    } else if (password !== "P99C@422853n$") {
      setVisible("wrong");
    } else if (password === "P99C@422853n$") {
      setShowData(true);
      setOpen(false);
      setVisible("");
      setPassword("");
      //set password and login time in local stroage
      localStorage.setItem("user_password", "P99C@422853n$");
      let timeD = new Date();
      ///console.log("check===>1",timeD,"and","and time",timeD.getTime())
      localStorage.setItem("date", timeD.getTime().toString());
    }
  };

  //time interval dropdown calling function
  const handleSelectChange: any = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPeriodString: any = e.target.value;
    const selectedPeriodObject = JSON.parse(selectedPeriodString);
    setSelectedPeriod(selectedPeriodObject);
    setIsPCP("ON");
  };

  //time period/range dropdown calling function
  const handleSelectTimeRange: any = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedRangeString: any = e.target.value;
    const selectedRangeObject = JSON.parse(selectedRangeString);
    if (selectedRangeObject.label === "Weekly") {
      setTimeRange("week");
      setSelectedPeriod(weekDefault);
    } else if (selectedRangeObject.label === "Daily") {
      setTimeRange("day");
      setSelectedPeriod(dayDefault);
    } else if (selectedRangeObject.label === "Monthly") {
      setTimeRange("month");
      setSelectedPeriod(monthDefault);
    } else if (selectedRangeObject.label === "Yearly") {
      setTimeRange("year");
      let yearDefault: any = { timeUnit: "max", name: "Max" };
      setSelectedPeriod(yearDefault);
    } else if (selectedRangeObject.label === "Quarterly") {
      setTimeRange("quarter");
      setSelectedPeriod(quarterDefault);
    } else if (selectedRangeObject.label === "Hourly") {
      setTimeRange("hour");
      setSelectedPeriod(hourDefault);
    }
    setIntervalControl(selectedRangeObject);
    setIsPCP("ON");
    if (getDropValues.id === 0) {
      setSaveIndicators([]);
      setSaveDraws([]);
    }
  };

  const upData2 = {
    id: getDropValues.id,
    mName: getDropValues.mName,
    status: 1,
    mData: {
      liveSymbolName: symName,
      pcpSymbolName: pcpName,
      saveSeriesTypeA: {
        id: symSeriesId.id,
        label: symSeriesId.label,
        icon: symSeriesId.icon,
      },
      saveSeriesTypeB: {
        id: pcpSeriesId.id,
        label: pcpSeriesId.label,
        icon: pcpSeriesId.icon,
      },
      timeUnit: timeRange,
      saveInterval: selectedPeriod,
      savePeriod: intervalControl,
      saveIndicators: saveIndicators,
      saveDrawings: saveDraws,
    },
  };

  if (JSON.parse(updateValues) !== null && getDropValues.id !== 0) {
    localStorage.setItem("update_data", JSON.stringify(upData2));
  }


  //lifecycle methods in react hooks - useEffect
  useEffect(() => {
    //initial calling
    let pass = localStorage.getItem("user_password");
    let passTime: any = localStorage.getItem("date");
    if (pass === null && passTime === null) {
      setOpen(true);
      //getDropCall();
    } else {
      setShowData(true);
      //localStorage.clear()
    }
    const intervalId = setInterval(() => {
      let tim = new Date().getTime();
      //console.log('min---->232342',tim,"and",passTime);

      let d = Math.floor((tim - passTime) / 1000);
      //console.log("sub===>",d, 10800)
      if (d > 10800) {
        localStorage.clear();
        setShowData(false);
      }
    }, 1000);

    //am5 stokc chart start code
    //1.setup root for stock chart
    let root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]); // set theme

    // Create a stock chart
    //stock series one - four symbols data chart (SPY,TQQQ,QQQ,SPXL)
    let stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {
        stockPositiveColor: am5.color("#000"),
        stockNegativeColor: am5.color("#000"),
        paddingRight: 0,
      })
    );
    //second stock series - client symbol data chart (PCP)
    let stockChart2 = root.container.children.push(
      am5stock.StockChart.new(root, {
        stockPositiveColor: am5.color("#F7F8FF"),
        stockNegativeColor: am5.color("#F7F8FF"), //#F7F8FF
        paddingRight: 0,
      })
    );

    // Set global number .format
    root.numberFormatter.set("numberFormat", "#,###.00");

    // Create a main stock panel (chart)
    let mainPanel = stockChart.panels.push(
      am5stock.StockPanel.new(root, {
        panX: true,
        panY: true,
        pinchZoomX: true,
        pinchZoomY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        visible: showData, // chart displaying condition
        marginTop: 30,
      })
    );
    //zoom actions in XY scales
    mainPanel.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        visible: false,
      })
    );

    mainPanel.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
        visible: false,
      })
    );
    let cursor = mainPanel.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
      })
    );
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    // Create value axis - one
    let valueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
          opposite: false, // left side Y-axis
        }),
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: "#,###.00",
        y: 20,
      })
    );
    valueAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      strokeOpacity: 0.1,
      stroke: am5.color("#fff"),
    });

    // create value axis - two
    let valueAxis2 = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
          opposite: true, // right side Y-axis
        }),
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: "#,###.00",
        y: 20,
        max:
          pcpName === "PCP106"
            ? 300
            : pcpName === "PCP103"
            ? 65
            : pcpName === "PCP112"
            ? 50
            : pcpName === "PCP111"
            ? 45
            : pcpName === "PCP" || pcpName === "PCP122"
            ? 100
            : pcpName === "PCP107"
            ? 70
            : 100,
        min:
          pcpName === "PCP106"
            ? -350
            : pcpName === "PCP103"
            ? 35
            : pcpName === "PCP107"
            ? 20
            : 0,
      })
    );
    valueAxis2.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      strokeOpacity: 0.1,
      stroke: am5.color("#fff"),
    });

    // create volume range on Y-axis (lines - 5,10,20,75,80,90)
    function createRange2(point: any, color: any) {
      let range = valueAxis2.makeDataItem({
        value: point,
      });
      valueAxis2.createAxisRange(range);
      range.get("label")?.setAll({
        fill: am5.color("#fff"),
        text: point + ".00",
        location: 1,
        background: am5.RoundedRectangle.new(root, {
          fill: color,
        }),
      });
      range.get("grid")?.setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1,
        strokeWidth: 3,
      });
    }

    let timeValue: any = timeRange; // define time unit value in get local state

    //create date axis
    let dateAxis = mainPanel.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit: timeValue === "quarter" ? "month" : timeValue,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    dateAxis.get("renderer").grid.template.setAll({
      location: 0,
      strokeWidth: 0,
      visible: false,
    });

    // Add series
    //value series one for four symbols data

    let valueSeries = mainPanel.series.push(
      d.new(root, {
        name: symName,
        valueXField: "Date",
        valueYField: "Close",
        highValueYField: "High",
        lowValueYField: "Low",
        openValueYField: "Open",
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: valueAxis,
        legendValueText:
          d === c
            ? "close: [bold]{valueY}[/]"
            : "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
        legendRangeValueText: "",
        fill: am5.color("#000"),
        stroke: am5.color("#000"),
        sequencedInterpolation: true,
        clustered: false,
      })
    );

    if (d === c) {
      valueSeries.strokes.template.setAll({
        strokeWidth: 2,
      });
    } else if (d !== c) {
      valueSeries.columns.template.setAll({
        strokeWidth: 2,
      });
    }
    if (symSeriesId?.id === "procandlestick") {
      valueSeries.columns.template.get("themeTags").push("pro");
    }

    //second value series for client symbol data
    let valueSeries2 = mainPanel.series.push(
      f.new(root, {
        name: pcpName,
        clustered: false,
        valueXField: "Date",
        valueYField: "Close",
        highValueYField: "High",
        lowValueYField: "Low",
        openValueYField: "Open",
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: valueAxis2,
        legendRangeValueText: "",
        sequencedInterpolation: true,
        legendValueText:
          f === c
            ? "close: [bold]{valueY}[/]"
            : "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
      })
    );

    if (f === c) {
      valueSeries2?.strokes.template.setAll({
        strokeWidth: 2.5,
        templateField: "strokeSettings",
      });
    } else if (f !== c) {
      valueSeries2.columns.template.setAll({
        strokeWidth: 2,
      });
      if (pcpSeriesId?.id === "procandlestick") {
        valueSeries2.columns.template.get("themeTags").push("pro");
      }

      // Customize the  wick color based on your condition
      valueSeries2.columns.template.adapters.add(
        "stroke",
        (fill: any, target: any) => {
          const candle: any = target.dataItem;
          if (pcpName === "PCP112") {
            if (candle.dataContext.Close < 5) {
              return am5.color("#0a8f0b");
            } else if (candle.dataContext.Close > 40) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP") {
            if (candle.dataContext.High >= 90) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.High >= 80 &&
              candle.dataContext.High < 90
            ) {
              return am5.color("#5E0208");
            } else if (
              candle.dataContext.High >= 75 &&
              candle.dataContext.High < 79.99
            ) {
              return am5.color("#7A0000");
            } else if (
              candle.dataContext.High >= 90 &&
              candle.dataContext.Close > 80
            ) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.High >= 80 &&
              candle.dataContext.Close > 75
            ) {
              return am5.color("#5E0208");
            } else if (
              candle.dataContext.Low > 10 &&
              candle.dataContext.Close < 20
            ) {
              return am5.color("#0a8f0b");
            } else if (
              candle.dataContext.Low > 5 &&
              candle.dataContext.Close < 10
            ) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.Low < 20 &&
              candle.dataContext.Low > 10
            ) {
              return am5.color("#0a8f0b");
            } else if (
              candle.dataContext.Low < 10 &&
              candle.dataContext.Low > 5
            ) {
              return am5.color("#005F33");
            } else if (candle.dataContext.Low < 5) {
              return am5.color("#182F28");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP100" && timeRange === "day") {
            if (candle.dataContext.High >= 18250) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.High >= 18000 &&
              candle.dataContext.High < 18250
            ) {
              return am5.color("#5E0208");
            } else if (
              candle.dataContext.High >= 17500 &&
              candle.dataContext.High < 17999.99
            ) {
              return am5.color("#7A0000");
            } else if (
              candle.dataContext.High >= 18250 &&
              candle.dataContext.Close > 18000
            ) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.High >= 17750 &&
              candle.dataContext.Close > 18000
            ) {
              return am5.color("#5E0208");
            } else if (
              candle.dataContext.Low > 15750 &&
              candle.dataContext.Close < 16000
            ) {
              return am5.color("#0a8f0b");
            } else if (
              candle.dataContext.Low > 15500 &&
              candle.dataContext.Close < 15750
            ) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.Low < 16000 &&
              candle.dataContext.Low > 15750
            ) {
              return am5.color("#0a8f0b");
            } else if (
              candle.dataContext.Low < 15750 &&
              candle.dataContext.Low > 15500
            ) {
              return am5.color("#005F33");
            } else if (candle.dataContext.Low < 15500) {
              return am5.color("#182F28");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP100" && timeRange === "week") {
            if (candle.dataContext.High >= 18000) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.High >= 17000 &&
              candle.dataContext.High < 18000
            ) {
              return am5.color("#5E0208");
            } else if (
              candle.dataContext.High >= 16000 &&
              candle.dataContext.High < 16999.99
            ) {
              return am5.color("#7A0000");
            } else if (
              candle.dataContext.High >= 18000 &&
              candle.dataContext.Close > 17000
            ) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.High >= 16000 &&
              candle.dataContext.Close > 17000
            ) {
              return am5.color("#5E0208");
            } else if (
              candle.dataContext.Low > 7000 &&
              candle.dataContext.Close < 9000
            ) {
              return am5.color("#0a8f0b");
            } else if (
              candle.dataContext.Low > 6000 &&
              candle.dataContext.Close < 7000
            ) {
              return am5.color("#182F28");
            } else if (
              candle.dataContext.Low < 9000 &&
              candle.dataContext.Low > 7000
            ) {
              return am5.color("#0a8f0b");
            } else if (
              candle.dataContext.Low < 7000 &&
              candle.dataContext.Low > 6000
            ) {
              return am5.color("#005F33");
            } else if (candle.dataContext.Low < 6000) {
              return am5.color("#182F28");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP103") {
            if (candle.dataContext.Close < 40) {
              return am5.color("#0a8f0b");
            } else if (candle.dataContext.Close > 60) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP106") {
            //pcp106
            if (candle.dataContext.Close < -250) {
              return am5.color("#0a8f0b");
            } else if (candle.dataContext.Close > 250) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP107") {
            if (candle.dataContext.Close < 25) {
              return am5.color("#0a8f0b");
            } else if (candle.dataContext.Close > 60) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP111") {
            if (candle.dataContext.Close < 5) {
              return am5.color("#0a8f0b");
            } else if (candle.dataContext.Close > 40) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#BFC3CA");
            }
          } else if (pcpName === "PCP122") {
            if (candle.dataContext.Close < 10) {
              return am5.color("#0a8f0b");
            } else if (candle.dataContext.Close > 90) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#BFC3CA");
            }
          }
        }
      );

      // Customize the body color based on your condition
      valueSeries2.columns.template.adapters.add(
        "fill",
        (fill: any, target: any) => {
          let dataItem: any = target.dataItem;
          if (pcpName === "PCP") {
            if (dataItem?.dataContext.Close > 90) {
              return am5.color("#182F28");
            } else if (
              dataItem?.dataContext.Close > 80 &&
              dataItem?.dataContext.Close < 90
            ) {
              return am5.color("#5E0208");
            } else if (
              dataItem?.dataContext.Close > 75 &&
              dataItem?.dataContext.Close < 80
            ) {
              return am5.color("#7A0000");
            } else if (
              dataItem?.dataContext.Close > 10 &&
              dataItem?.dataContext.Close < 20
            ) {
              return am5.color("#0a8f0b");
            } else if (
              dataItem?.dataContext.Close > 5 &&
              dataItem?.dataContext.Close < 10
            ) {
              return am5.color("#005F33");
            } else if (dataItem?.dataContext.Close < 5) {
              return am5.color("#182F28");
            }
            return am5.color("#F7F8FF");
          } else if (pcpName === "PCP100" && timeRange === "day") {
            if (dataItem?.dataContext.Close > 18250) {
              return am5.color("#182F28");
            } else if (
              dataItem?.dataContext.Close > 18000 &&
              dataItem?.dataContext.Close < 18250
            ) {
              return am5.color("#5E0208");
            } else if (
              dataItem?.dataContext.Close > 17750 &&
              dataItem?.dataContext.Close < 18000
            ) {
              return am5.color("#7A0000");
            } else if (
              dataItem?.dataContext.Close > 15750 &&
              dataItem?.dataContext.Close < 16000
            ) {
              return am5.color("#0a8f0b");
            } else if (
              dataItem?.dataContext.Close > 15500 &&
              dataItem?.dataContext.Close < 15750
            ) {
              return am5.color("#005F33");
            } else if (dataItem?.dataContext.Close < 15500) {
              return am5.color("#182F28");
            }
            return am5.color("#F7F8FF");
          } else if (pcpName === "PCP100" && timeRange === "week") {
            if (dataItem?.dataContext.Close > 18000) {
              return am5.color("#182F28");
            } else if (
              dataItem?.dataContext.Close > 17000 &&
              dataItem?.dataContext.Close < 18000
            ) {
              return am5.color("#5E0208");
            } else if (
              dataItem?.dataContext.Close > 16000 &&
              dataItem?.dataContext.Close < 17000
            ) {
              return am5.color("#7A0000");
            } else if (
              dataItem?.dataContext.Close > 7000 &&
              dataItem?.dataContext.Close < 9000
            ) {
              return am5.color("#0a8f0b");
            } else if (
              dataItem?.dataContext.Close > 6000 &&
              dataItem?.dataContext.Close < 7000
            ) {
              return am5.color("#005F33");
            } else if (dataItem?.dataContext.Close < 6000) {
              return am5.color("#182F28");
            }
            return am5.color("#F7F8FF");
          } else if (pcpName === "PCP103") {
            if (dataItem?.dataContext.Close < 40) {
              return am5.color("#0a8f0b");
            } else if (dataItem?.dataContext.Close > 60) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#F7F8FF");
            }
          } else if (pcpName === "PCP107") {
            //PCP107
            if (dataItem?.dataContext.Close < 25) {
              return am5.color("#0a8f0b");
            } else if (dataItem?.dataContext.Close > 60) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#F7F8FF");
            }
          } else if (pcpName === "PCP106") {
            //PCP106
            if (dataItem?.dataContext.Close < -250) {
              return am5.color("#0a8f0b");
            } else if (dataItem?.dataContext.Close > 250) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#F7F8FF");
            }
          } else if (pcpName === "PCP111") {
            //PCP111
            if (dataItem?.dataContext.Close < 5) {
              return am5.color("#0a8f0b");
            } else if (dataItem?.dataContext.Close > 40) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#F7F8FF");
            }
          } else if (pcpName === "PCP112") {
            //PCP112
            if (dataItem?.dataContext.Close < 5) {
              return am5.color("#0a8f0b");
            } else if (dataItem?.dataContext.Close > 40) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#F7F8FF");
            }
          } else if (pcpName === "PCP122") {
            if (dataItem?.dataContext.Close < 10) {
              return am5.color("#0a8f0b");
            } else if (dataItem?.dataContext.Close > 90) {
              return am5.color("#7A0000");
            } else {
              return am5.color("#F7F8FF");
            }
          }
        }
      );
    }

    if (isPCP === "ON") {
      if (pcpName === "PCP") {
        // console.log("check1----->1",pcpName);
        createRange2(5, am5.color("#182F28")); //dark green
        createRange2(10, am5.color("#005F33")); //regular green
        createRange2(20, am5.color("#0a8f0b")); //light green
        createRange2(75, am5.color("#7A0000")); //red #E93424
        createRange2(80, am5.color("#5E0208")); //regular red #A92317
        createRange2(90, am5.color("#182F28")); //dark green
      } else if (pcpName === "PCP122") {
        createRange2(10, am5.color("#0a8f0b")); //regular green
        createRange2(90, am5.color("#7A0000")); //red #E93424
      } else if (pcpName === "PCP100" && timeRange === "week") {
        //pcp100
        createRange2(6000, am5.color("#182F28")); //dark green
        createRange2(7000, am5.color("#005F33")); //regular green
        createRange2(9000, am5.color("#0a8f0b")); //light green
        createRange2(16000, am5.color("#7A0000")); //red #E93424
        createRange2(17000, am5.color("#5E0208")); //regular red #A92317
        createRange2(18000, am5.color("#182F28")); //dark green
      } else if (pcpName === "PCP100" && timeRange === "day") {
        createRange2(15500, am5.color("#182F28")); //dark green
        createRange2(15750, am5.color("#005F33")); //regular green
        createRange2(16000, am5.color("#0a8f0b")); //light green
        createRange2(17750, am5.color("#7A0000")); //red #E93424
        createRange2(18000, am5.color("#5E0208")); //regular red #A92317
        createRange2(18250, am5.color("#182F28")); //dark green
      } else if (pcpName === "PCP103") {
        //pcp103

        createRange2(40, am5.color("#0a8f0b")); //light green
        createRange2(60, am5.color("#7A0000")); //red #E93424
      } else if (pcpName === "PCP106") {
        //PCP106 day and week
        createRange2(-250, am5.color("#0a8f0b")); //light green
        createRange2(250, am5.color("#7A0000")); //red #E93424
      } else if (pcpName === "PCP107") {
        //pcp107
        createRange2(25, am5.color("#0a8f0b")); //light green
        createRange2(60, am5.color("#7A0000")); //red #E93424
      } else if (pcpName === "PCP111") {
        //PCP111 day and week
        createRange2(5, am5.color("#0a8f0b")); //light green
        createRange2(40, am5.color("#7A0000")); //red #E93424
      } else if (pcpName === "PCP112") {
        //PCP112 day and week

        createRange2(5, am5.color("#0a8f0b")); //light green
        createRange2(40, am5.color("#7A0000")); //red #E93424
      }
    } else {
      resetRanges();
    }

    function resetRanges() {
      valueAxis2.axisRanges.each((range) => {
        range.get("label")?.setAll({
          fill: undefined,
          text: undefined,
          location: undefined,
          background: undefined,
        });
        range.get("grid")?.setAll({
          stroke: undefined,
          strokeOpacity: undefined,
          location: undefined,
          strokeWidth: undefined,
        });
      });
    }

    //set data both series in stock charts
    stockChart2.set("stockSeries", valueSeries2);
    stockChart.set("stockSeries", valueSeries);

    // Add a stock legend
    let valueLegend = mainPanel.plotContainer.children.push(
      am5stock.StockLegend.new(root, {
        stockChart: stockChart,
        y: -35,
        x: -60,
      })
    );

    // Create volume axis's
    let volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
    });
    volumeAxisRenderer.labels.template.set("forceHidden", true);
    volumeAxisRenderer.grid.template.set("forceHidden", true);

    let volumeValueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#.#a",
        height: am5.percent(20),
        y: am5.percent(100),
        centerY: am5.percent(100),
        renderer: volumeAxisRenderer,
      })
    );

    // Add vloume series one
    let volumeSeries = mainPanel.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Volume",
        clustered: false,
        valueXField: "Date",
        valueYField: "Volume",
        xAxis: dateAxis,
        yAxis: volumeValueAxis,
        legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
      })
    );

    volumeSeries.columns.template.setAll({
      strokeOpacity: 0,
      fillOpacity: 0.5,
    });

    //add second volume series for client pcp
    let volumeAxisRenderer2 = am5xy.AxisRendererY.new(root, {
      inside: true,
    });
    volumeAxisRenderer2.labels.template.set("forceHidden", true);
    volumeAxisRenderer2.grid.template.set("forceHidden", true);

    let volumeValueAxis2 = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#.#a",
        height: am5.percent(20),
        y: am5.percent(100),
        centerY: am5.percent(100),
        renderer: volumeAxisRenderer2,
      })
    );

    let volumeSeries2 = mainPanel.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Volume",
        clustered: false,
        valueXField: "Date",
        valueYField: "Volume",
        xAxis: dateAxis,
        yAxis: volumeValueAxis2,
        legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
      })
    );

    volumeSeries2.columns.template.setAll({
      strokeOpacity: 0,
      fillOpacity: 0.5,
    });

    // Set main series
    stockChart.set("volumeSeries", volumeSeries);
    stockChart2.set("volumeSeries", volumeSeries2);
    valueLegend.data.setAll([valueSeries, valueSeries2]); // set value legend both series
    valueLegend.events.on("click", (event: any) => {
      let d =
        event.target.dataItems[1].dataContext._isHidden === true ? "OFF" : "ON";
      setIsPCP(d);
      if (d === "OFF") {
        resetRanges();
        valueAxis2.get("renderer").labels.template.setAll({
          fill: am5.color("#fff"),
        });
      }
      if (d === "ON") {
        if (pcpName === "PCP") {
          // console.log("check1----->1",pcpName);
          createRange2(5, am5.color("#182F28")); //dark green
          createRange2(10, am5.color("#005F33")); //regular green
          createRange2(20, am5.color("#0a8f0b")); //light green
          createRange2(75, am5.color("#7A0000")); //red #E93424
          createRange2(80, am5.color("#5E0208")); //regular red #A92317
          createRange2(90, am5.color("#182F28")); //dark green
        } else if (pcpName === "PCP122") {
          createRange2(10, am5.color("#0a8f0b")); //regular green
          createRange2(90, am5.color("#7A0000")); //red #E93424
        } else if (pcpName === "PCP100" && timeRange === "week") {
          //pcp100
          createRange2(6000, am5.color("#182F28")); //dark green
          createRange2(7000, am5.color("#005F33")); //regular green
          createRange2(9000, am5.color("#0a8f0b")); //light green
          createRange2(16000, am5.color("#7A0000")); //red #E93424
          createRange2(17000, am5.color("#5E0208")); //regular red #A92317
          createRange2(18000, am5.color("#182F28")); //dark green
        } else if (pcpName === "PCP100" && timeRange === "day") {
          createRange2(15500, am5.color("#182F28")); //dark green
          createRange2(15750, am5.color("#005F33")); //regular green
          createRange2(16000, am5.color("#0a8f0b")); //light green
          createRange2(17750, am5.color("#7A0000")); //red #E93424
          createRange2(18000, am5.color("#5E0208")); //regular red #A92317
          createRange2(18250, am5.color("#182F28")); //dark green
        } else if (pcpName === "PCP103") {
          //pcp103

          createRange2(40, am5.color("#0a8f0b")); //light green
          createRange2(60, am5.color("#7A0000")); //red #E93424
        } else if (pcpName === "PCP106") {
          //PCP106 day and week
          createRange2(-250, am5.color("#0a8f0b")); //light green
          createRange2(250, am5.color("#7A0000")); //red #E93424
        } else if (pcpName === "PCP107") {
          //pcp107
          createRange2(25, am5.color("#0a8f0b")); //light green
          createRange2(60, am5.color("#7A0000")); //red #E93424
        } else if (pcpName === "PCP111") {
          //PCP111 day and week
          createRange2(5, am5.color("#0a8f0b")); //light green
          createRange2(40, am5.color("#7A0000")); //red #E93424
        } else if (pcpName === "PCP112") {
          //PCP112 day and week

          createRange2(5, am5.color("#0a8f0b")); //light green
          createRange2(40, am5.color("#7A0000")); //red #E93424
        }
      }

      let d2 =
        event.target.dataItems[0].dataContext._isHidden === true ? "ON" : "OFF";
      setIsSPY(d2);
      if (d2 === "OFF") {
        valueAxis.get("renderer").labels.template.setAll({
          fill: am5.color("#000"),
        });
      } else {
        valueAxis.get("renderer").labels.template.setAll({
          fill: am5.color("#fff"),
        });
      }
    });

    // Add cursor(s) both series
    mainPanel.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        yAxis: valueAxis,
        xAxis: dateAxis,
        snapToSeries: [valueSeries, valueSeries2],
        snapToSeriesBy: "y!",
      })
    );
    mainPanel.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        yAxis: valueAxis2,
        xAxis: dateAxis,
        snapToSeries: [valueSeries2, valueSeries],
        snapToSeriesBy: "y!",
      })
    );

    // Add scrollbar with zoom
    let scrollbar = mainPanel.set(
      "scrollbarX",
      am5xy.XYChartScrollbar.new(root, {
        orientation: "horizontal",
        height: 50,
        forceHidden: true,
      })
    );

    stockChart.toolsContainer.children.push(scrollbar);

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit: timeValue === "quarter" ? "year" : timeValue,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    let sbValueAxis = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let sbSeries = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: "Close",
        valueXField: "Date",
        xAxis: sbDateAxis,
        yAxis: sbValueAxis,
      })
    );

    sbSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.3,
    });

    // Function that dynamically loads data
    function loadData(ticker: any, series: any, granularity: any) {
      const randomParam = Math.random();

      let Base = `https://financialindicator.s3.ap-south-1.amazonaws.com/${ticker}/${granularity}.json?random=${randomParam}`;
      //let Base = `https://financeindicatordev.s3.ap-south-1.amazonaws.com/${ticker}/${granularity}.json?random=${randomParam}`;
      am5.net
        .load(Base)
        .then(function (result: any) {
          // Parse loaded data
          let data = am5.JSONParser.parse(result.response, {
            reverse: true,
          });

          // Process data (convert dates and values)
          let processor = am5.DataProcessor.new(root, {
            dateFields: ["Date"],
            dateFormat: "yyyy-MM-dd",
            numericFields: ["Open", "High", "Low", "Close"],
          });

          processor.processMany(data);

          // Set data
          am5.array.each(series, function (item: any) {
            item.data.setAll(data);
            //setLoader(false);
          });
        })
        .catch(function (err: any) {
          //console.log("err symbol===>2",err)
        });
    }

    // Load initial data for the first series
    let currentGranularity = timeRange;

    loadData(symName, [valueSeries, sbSeries], currentGranularity);
    loadData(pcpName, [valueSeries2, sbSeries], currentGranularity);

    // Set up main indices selector
    let mainSeriesControl = am5stock.DropdownListControl.new(root, {
      stockChart: stockChart,
      name: symName,
      icon: am5stock.StockIcons.getIcon("Search"),
      fixedLabel: true,
      searchable: true,
      searchCallback: function (query) {
        let mainSeries = stockChart.get("stockSeries");
        let mainSeriesID = mainSeries ? mainSeries.get("name") : "";
        let list = getTicker(query);
        am5.array.each(list, function (item: any) {
          if (item.id === mainSeriesID) {
            item.disabled = true;
          }
        });
        return list;
      },
    });

    mainSeriesControl.events.on("selected", function (ev: any) {
      let valueSeries = stockChart.get("stockSeries");
      setSymName(ev.item.subLabel);
      setSaveDraws([]);
      setSaveIndicators([]);
      mainSeriesControl.set("name", ev.item.subLabel);
      valueSeries?.set("name", ev.item.subLabel);
      loadData(ev.item.subLabel, [valueSeries, sbSeries], currentGranularity);
    });

    function getTicker(search: string) {
      if (search === "") {
        return [];
      }
      search = search.toLowerCase();
      //four symbols data
      let tickers = [
        {
          _id: "654c606f28cdb9cbab70a962",
          label: "SPDR S&P 500 ETF Trust",
          subLabel: "SPY",
          id: "SPY",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a963",
          label: "Invesco QQQ",
          subLabel: "QQQ",
          id: "QQQ",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a964",
          label: "ProShares UltraPro QQQ",
          subLabel: "TQQQ",
          id: "TQQQ",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a965",
          label: "Direxion Daily S&P500 Bull 3X Shares",
          subLabel: "SPXL",
          id: "SPXL",
          __v: 0,
        },
        {
          label: "Direxion Daily Homebuilders & Supplies Bull 3X Shares",
          subLabel: "NAIL",
          id: "NAIL",
        },
        { label: "iShares Russell 2000 ETF", subLabel: "IWM", id: "IWM" },

        {
          label: "Direxion Daily Small Cap Bull 3X Shares",
          subLabel: "TNA",
          id: "TNA",
        },
        {
          label: "ProShares UltraPro Short QQQ",
          subLabel: "SQQQ",
          id: "SQQQ",
        },
        {
          label: "Direxion Daily Small Cap Bear 3X Shares",
          subLabel: "TZA",
          id: "TZA",
        },
        {
          label: "ProShares UltraPro Short S&P500",
          subLabel: "SPXU",
          id: "SPXU",
        },
      ];

      return tickers.filter(function (item) {
        return (
          item.label.toLowerCase().match(search) ||
          item.subLabel.toLowerCase().match(search)
        );
      });
    }

    // set up second indices selector
    let mainSeriesControl2 = am5stock.DropdownListControl.new(root, {
      stockChart: stockChart2,
      name: valueSeries2.get("name"),
      icon: am5stock.StockIcons.getIcon("Search"),
      fixedLabel: true,
      searchable: true,
      searchCallback: function (query) {
        let mainSeries = stockChart2.get("stockSeries");
        let mainSeriesID = mainSeries ? mainSeries.get("name") : "";
        let list = getTicker2(query);
        am5.array.each(list, function (item: any) {
          if (item.id === mainSeriesID) {
            item.disabled = true;
          }
        });
        return list;
      },
    });

    mainSeriesControl2.events.on("selected", function (ev: any) {
      let valueSeries2 = stockChart2.get("stockSeries");
      setPcpName(ev.item.subLabel);
      setSaveDraws([]);
      setSaveIndicators([]);
      mainSeriesControl2.set("name", ev.item.subLabel);
      valueSeries2?.set("name", ev.item.subLabel);
      loadData(ev.item.subLabel, [valueSeries2, sbSeries], currentGranularity);
    });

    function getTicker2(search: string) {
      if (search === "") {
        return [];
      }
      search = search.toLowerCase();
      //four symbols data
      let tickers2 = [
        {
          _id: "654c606f28cdb9cbab70a962",
          label: "PCP",
          subLabel: "PCP",
          id: "PCP",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a964",
          label: "PCP103",
          subLabel: "PCP103",
          id: "PCP103",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a965",
          label: "PCP106",
          subLabel: "PCP106",
          id: "PCP106",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a965",
          label: "PCP107",
          subLabel: "PCP107",
          id: "PCP107",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a965",
          label: "PCP111",
          subLabel: "PCP111",
          id: "PCP111",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a965",
          label: "PCP112",
          subLabel: "PCP112",
          id: "PCP112",
          __v: 0,
        },
        {
          _id: "654c606f28cdb9cbab70a965",
          label: "PCP122",
          subLabel: "PCP122",
          id: "PCP122",
          __v: 0,
        },
      ];

      return tickers2.filter(function (item) {
        return (
          item.label.toLowerCase().match(search) ||
          item.subLabel.toLowerCase().match(search)
        );
      });
    }
    // Set up series type switcher one

    let seriesSwitcher = am5stock.SeriesTypeControl.new(root, {
      stockChart: stockChart,
    });

    seriesSwitcher.events.on("selected", function (ev: any) {
      //let name = seriesSwitcher.get("name")?.toString();
      setSeriesType(ev.item.id);
      icons.map((it) => {
        if (ev?.item?.id === it?.id) {
          setSymSeriesId({
            id: it?.id,
            label: it?.label,
            icon: it?.icon,
          });
        }
        return it;
      });
    });
    if (JSON.parse(updateValues) === null) {
      seriesSwitcher.setItem({
        id: symSeriesId?.id,
        label: symSeriesId?.label,
        icon: am5stock.StockIcons.getIcon(symSeriesId?.icon),
      });
    } else if (JSON.parse(updateValues) !== null) {
      seriesSwitcher.setItem({
        id: symSeriesId?.id,
        label: symSeriesId?.label,
        icon: am5stock.StockIcons.getIcon(symSeriesId?.icon),
      });
    }

    //let swId : any = symSeriesId?.id
    //setSeriesType(swId)

    function getNewSettings(series: any) {
      let newSettings: any = [];
      am5.array.each(
        [
          "name",
          "valueYField",
          "highValueYField",
          "lowValueYField",
          "openValueYField",
          "calculateAggregates",
          "valueXField",
          "xAxis",
          "yAxis",
          "legendValueText",
          "stroke",
          "fill",
          "legendRangeValueText",
        ],
        function (setting) {
          newSettings[setting] = series.get(setting);
        }
      );
      return newSettings;
    }

    function setSeriesType(seriesType: any) {
      // Get current series and its settings
      let currentSeries: any = stockChart.get("stockSeries");
      let newSettings = getNewSettings(currentSeries);

      // Remove previous series
      let data = currentSeries.data.values;
      mainPanel.series.removeValue(currentSeries);

      // Create new series
      let series: any = {};
      switch (seriesType) {
        case "line":
          series = mainPanel.series.push(
            am5xy.LineSeries.new(root, {
              name: symName,
              valueXField: "Date",
              valueYField: "Close",
              highValueYField: "High",
              lowValueYField: "Low",
              openValueYField: "Open",
              calculateAggregates: true,
              xAxis: dateAxis,
              yAxis: valueAxis,
              legendValueText: "close: [bold]{valueY}[/]",
              legendRangeValueText: "",
              fill: am5.color("#000"),
              stroke: am5.color("#000"),
            })
          );
          series?.strokes.template.setAll({
            strokeWidth: 2,
          });
          break;
        case "candlestick":
        case "procandlestick":
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.CandlestickSeries.new(root, {
              name: symName,
              clustered: false,
              valueXField: "Date",
              valueYField: "Close",
              highValueYField: "High",
              lowValueYField: "Low",
              openValueYField: "Open",
              calculateAggregates: true,
              xAxis: dateAxis,
              yAxis: valueAxis,
              legendValueText:
                "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
              legendRangeValueText: "",
              sequencedInterpolation: true,
            })
          );

          if (seriesType === "procandlestick") {
            series.columns.template.get("themeTags").push("pro");
          }
          break;
        case "ohlc":
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.OHLCSeries.new(root, {
              name: symName,
              clustered: false,
              valueXField: "Date",
              valueYField: "Close",
              highValueYField: "High",
              lowValueYField: "Low",
              openValueYField: "Open",
              calculateAggregates: true,
              xAxis: dateAxis,
              yAxis: valueAxis,
              legendValueText:
                "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
              legendRangeValueText: "",
              sequencedInterpolation: true,
            })
          );

          break;
      }

      // Set new series as stockSeries one
      if (series) {
        valueLegend.data.removeValue(currentSeries);
        series.data.setAll(data);
        stockChart.set("stockSeries", series);
        let cursor = mainPanel.get("cursor");
        if (cursor) {
          cursor.set("snapToSeries", [series]);
        }
        valueLegend.data.insertIndex(0, series);
      }
    }

    //set up second series switcher for client pcp data
    let seriesSwitcher2 = am5stock.SeriesTypeControl.new(root, {
      stockChart: stockChart2,
      description: "PCP",
    });

    seriesSwitcher2.events.on("selected", function (ev: any) {
      setSeriesType2(ev.item.id);
      icons.map((it) => {
        if (ev?.item?.id === it?.id) {
          setPcpSeriesId({
            id: it?.id,
            label: it?.label,
            icon: it?.icon,
          });
        }
        return it;
      });
      //console.log(ev.item.id)
    });

    if (JSON.parse(updateValues) === null) {
      seriesSwitcher2.setItem({
        id: pcpSeriesId?.id,
        label: pcpSeriesId?.label,
        icon: am5stock.StockIcons.getIcon(pcpSeriesId?.icon),
      });
    } else if (JSON.parse(updateValues) !== null) {
      seriesSwitcher2.setItem({
        id: pcpSeriesId?.id,
        label: pcpSeriesId?.label,
        icon: am5stock.StockIcons.getIcon(pcpSeriesId?.icon),
      });
    }

    //setSeriesType2(pcpSeriesId?.id)

    function getNewSettings2(series: any) {
      let newSettings: any = [];
      am5.array.each(
        [
          "name",
          "valueYField",
          "highValueYField",
          "lowValueYField",
          "openValueYField",
          "calculateAggregates",
          "valueXField",
          "xAxis",
          "yAxis",
          "legendValueText",
          "stroke",
          "fill",
          "legendRangeValueText",
        ],
        function (setting) {
          newSettings[setting] = series.get(setting);
        }
      );
      return newSettings;
    }

    function setSeriesType2(seriesType: any) {
      // Get current series and its settings
      let currentSeries: any = stockChart2.get("stockSeries");
      let newSettings = getNewSettings2(currentSeries);

      // Remove previous series
      let data = currentSeries.data.values;
      mainPanel.series.removeValue(currentSeries);

      // Create new series
      let series: any = {};
      switch (seriesType) {
        case "line":
          series = mainPanel.series.push(
            am5xy.LineSeries.new(root, {
              name: pcpName,
              valueXField: "Date",
              valueYField: "Close",
              highValueYField: "High",
              lowValueYField: "Low",
              openValueYField: "Open",
              calculateAggregates: true,
              xAxis: dateAxis,
              yAxis: valueAxis2,
              legendValueText: "close: [bold]{valueY}[/]",
              legendRangeValueText: "",
            })
          );
          series?.strokes.template.setAll({
            strokeWidth: 2.5,
            templateField: "strokeSettings",
          });
          break;
        case "candlestick":
        case "procandlestick":
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.CandlestickSeries.new(root, {
              name: pcpName,
              clustered: false,
              valueXField: "Date",
              valueYField: "Close",
              highValueYField: "High",
              lowValueYField: "Low",
              openValueYField: "Open",
              calculateAggregates: true,
              xAxis: dateAxis,
              yAxis: valueAxis2,
              legendValueText:
                "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
              legendRangeValueText: "",
              sequencedInterpolation: true,
            })
          );
          series.columns.template.setAll({
            strokeWidth: 2,
          });

          // Customize the  wick color based on your condition
          series.columns.template.adapters.add(
            "stroke",
            (fill: any, target: any) => {
              const candle: any = target.dataItem;
              if (pcpName === "PCP112") {
                if (candle.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP") {
                if (candle.dataContext.High >= 90) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 80 &&
                  candle.dataContext.High < 90
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.High >= 75 &&
                  candle.dataContext.High < 79.99
                ) {
                  return am5.color("#7A0000");
                } else if (
                  candle.dataContext.High >= 90 &&
                  candle.dataContext.Close > 80
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 80 &&
                  candle.dataContext.Close > 75
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.Low > 10 &&
                  candle.dataContext.Close < 20
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low > 5 &&
                  candle.dataContext.Close < 10
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.Low < 20 &&
                  candle.dataContext.Low > 10
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low < 10 &&
                  candle.dataContext.Low > 5
                ) {
                  return am5.color("#005F33");
                } else if (candle.dataContext.Low < 5) {
                  return am5.color("#182F28");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP100" && timeRange === "day") {
                if (candle.dataContext.High >= 18250) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 18000 &&
                  candle.dataContext.High < 18250
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.High >= 17500 &&
                  candle.dataContext.High < 17999.99
                ) {
                  return am5.color("#7A0000");
                } else if (
                  candle.dataContext.High >= 18250 &&
                  candle.dataContext.Close > 18000
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 17750 &&
                  candle.dataContext.Close > 18000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.Low > 15750 &&
                  candle.dataContext.Close < 16000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low > 15500 &&
                  candle.dataContext.Close < 15750
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.Low < 16000 &&
                  candle.dataContext.Low > 15750
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low < 15750 &&
                  candle.dataContext.Low > 15500
                ) {
                  return am5.color("#005F33");
                } else if (candle.dataContext.Low < 15500) {
                  return am5.color("#182F28");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP100" && timeRange === "week") {
                if (candle.dataContext.High >= 18000) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 17000 &&
                  candle.dataContext.High < 18000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.High >= 16000 &&
                  candle.dataContext.High < 16999.99
                ) {
                  return am5.color("#7A0000");
                } else if (
                  candle.dataContext.High >= 18000 &&
                  candle.dataContext.Close > 17000
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 16000 &&
                  candle.dataContext.Close > 17000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.Low > 7000 &&
                  candle.dataContext.Close < 9000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low > 6000 &&
                  candle.dataContext.Close < 7000
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.Low < 9000 &&
                  candle.dataContext.Low > 7000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low < 7000 &&
                  candle.dataContext.Low > 6000
                ) {
                  return am5.color("#005F33");
                } else if (candle.dataContext.Low < 6000) {
                  return am5.color("#182F28");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP103") {
                if (candle.dataContext.Close < 40) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP106") {
                //pcp106
                if (candle.dataContext.Close < -250) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 250) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP107") {
                if (candle.dataContext.Close < 25) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP111") {
                if (candle.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP122") {
                if (candle.dataContext.Close < 10) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 90) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              }
            }
          );

          // Customize the body color based on your condition
          series.columns.template.adapters.add(
            "fill",
            (fill: any, target: any) => {
              let dataItem: any = target.dataItem;
              if (pcpName === "PCP") {
                if (dataItem?.dataContext.Close > 90) {
                  return am5.color("#182F28");
                } else if (
                  dataItem?.dataContext.Close > 80 &&
                  dataItem?.dataContext.Close < 90
                ) {
                  return am5.color("#5E0208");
                } else if (
                  dataItem?.dataContext.Close > 75 &&
                  dataItem?.dataContext.Close < 80
                ) {
                  return am5.color("#7A0000");
                } else if (
                  dataItem?.dataContext.Close > 10 &&
                  dataItem?.dataContext.Close < 20
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  dataItem?.dataContext.Close > 5 &&
                  dataItem?.dataContext.Close < 10
                ) {
                  return am5.color("#005F33");
                } else if (dataItem?.dataContext.Close < 5) {
                  return am5.color("#182F28");
                }
                return am5.color("#F7F8FF");
              } else if (pcpName === "PCP100" && timeRange === "day") {
                if (dataItem?.dataContext.Close > 18250) {
                  return am5.color("#182F28");
                } else if (
                  dataItem?.dataContext.Close > 18000 &&
                  dataItem?.dataContext.Close < 18250
                ) {
                  return am5.color("#5E0208");
                } else if (
                  dataItem?.dataContext.Close > 17750 &&
                  dataItem?.dataContext.Close < 18000
                ) {
                  return am5.color("#7A0000");
                } else if (
                  dataItem?.dataContext.Close > 15750 &&
                  dataItem?.dataContext.Close < 16000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  dataItem?.dataContext.Close > 15500 &&
                  dataItem?.dataContext.Close < 15750
                ) {
                  return am5.color("#005F33");
                } else if (dataItem?.dataContext.Close < 15500) {
                  return am5.color("#182F28");
                }
                return am5.color("#F7F8FF");
              } else if (pcpName === "PCP100" && timeRange === "week") {
                if (dataItem?.dataContext.Close > 18000) {
                  return am5.color("#182F28");
                } else if (
                  dataItem?.dataContext.Close > 17000 &&
                  dataItem?.dataContext.Close < 18000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  dataItem?.dataContext.Close > 16000 &&
                  dataItem?.dataContext.Close < 17000
                ) {
                  return am5.color("#7A0000");
                } else if (
                  dataItem?.dataContext.Close > 7000 &&
                  dataItem?.dataContext.Close < 9000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  dataItem?.dataContext.Close > 6000 &&
                  dataItem?.dataContext.Close < 7000
                ) {
                  return am5.color("#005F33");
                } else if (dataItem?.dataContext.Close < 6000) {
                  return am5.color("#182F28");
                }
                return am5.color("#F7F8FF");
              } else if (pcpName === "PCP103") {
                if (dataItem?.dataContext.Close < 40) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP107") {
                //PCP107
                if (dataItem?.dataContext.Close < 25) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP106") {
                //PCP106
                if (dataItem?.dataContext.Close < -250) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 250) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP111") {
                //PCP111
                if (dataItem?.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP112") {
                //PCP112
                if (dataItem?.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP122") {
                if (dataItem?.dataContext.Close < 10) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 90) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              }
            }
          );

          if (seriesType === "procandlestick") {
            series.columns.template.get("themeTags").push("pro");
            series.columns.template.setAll({
              strokeWidth: 2,
            });

            // Customize the  wick color based on your condition
            series.columns.template.adapters.add(
              "stroke",
              (fill: any, target: any) => {
                const candle: any = target.dataItem;
                if (pcpName === "PCP112") {
                  if (candle.dataContext.Close < 5) {
                    return am5.color("#0a8f0b");
                  } else if (candle.dataContext.Close > 40) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP") {
                  if (candle.dataContext.High >= 90) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.High >= 80 &&
                    candle.dataContext.High < 90
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    candle.dataContext.High >= 75 &&
                    candle.dataContext.High < 79.99
                  ) {
                    return am5.color("#7A0000");
                  } else if (
                    candle.dataContext.High >= 90 &&
                    candle.dataContext.Close > 80
                  ) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.High >= 80 &&
                    candle.dataContext.Close > 75
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    candle.dataContext.Low > 10 &&
                    candle.dataContext.Close < 20
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    candle.dataContext.Low > 5 &&
                    candle.dataContext.Close < 10
                  ) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.Low < 20 &&
                    candle.dataContext.Low > 10
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    candle.dataContext.Low < 10 &&
                    candle.dataContext.Low > 5
                  ) {
                    return am5.color("#005F33");
                  } else if (candle.dataContext.Low < 5) {
                    return am5.color("#182F28");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP100" && timeRange === "day") {
                  if (candle.dataContext.High >= 18250) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.High >= 18000 &&
                    candle.dataContext.High < 18250
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    candle.dataContext.High >= 17500 &&
                    candle.dataContext.High < 17999.99
                  ) {
                    return am5.color("#7A0000");
                  } else if (
                    candle.dataContext.High >= 18250 &&
                    candle.dataContext.Close > 18000
                  ) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.High >= 17750 &&
                    candle.dataContext.Close > 18000
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    candle.dataContext.Low > 15750 &&
                    candle.dataContext.Close < 16000
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    candle.dataContext.Low > 15500 &&
                    candle.dataContext.Close < 15750
                  ) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.Low < 16000 &&
                    candle.dataContext.Low > 15750
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    candle.dataContext.Low < 15750 &&
                    candle.dataContext.Low > 15500
                  ) {
                    return am5.color("#005F33");
                  } else if (candle.dataContext.Low < 15500) {
                    return am5.color("#182F28");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP100" && timeRange === "week") {
                  if (candle.dataContext.High >= 18000) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.High >= 17000 &&
                    candle.dataContext.High < 18000
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    candle.dataContext.High >= 16000 &&
                    candle.dataContext.High < 16999.99
                  ) {
                    return am5.color("#7A0000");
                  } else if (
                    candle.dataContext.High >= 18000 &&
                    candle.dataContext.Close > 17000
                  ) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.High >= 16000 &&
                    candle.dataContext.Close > 17000
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    candle.dataContext.Low > 7000 &&
                    candle.dataContext.Close < 9000
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    candle.dataContext.Low > 6000 &&
                    candle.dataContext.Close < 7000
                  ) {
                    return am5.color("#182F28");
                  } else if (
                    candle.dataContext.Low < 9000 &&
                    candle.dataContext.Low > 7000
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    candle.dataContext.Low < 7000 &&
                    candle.dataContext.Low > 6000
                  ) {
                    return am5.color("#005F33");
                  } else if (candle.dataContext.Low < 6000) {
                    return am5.color("#182F28");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP103") {
                  if (candle.dataContext.Close < 40) {
                    return am5.color("#0a8f0b");
                  } else if (candle.dataContext.Close > 60) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP106") {
                  //pcp106
                  if (candle.dataContext.Close < -250) {
                    return am5.color("#0a8f0b");
                  } else if (candle.dataContext.Close > 250) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP107") {
                  if (candle.dataContext.Close < 25) {
                    return am5.color("#0a8f0b");
                  } else if (candle.dataContext.Close > 60) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP111") {
                  if (candle.dataContext.Close < 5) {
                    return am5.color("#0a8f0b");
                  } else if (candle.dataContext.Close > 40) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                } else if (pcpName === "PCP122") {
                  if (candle.dataContext.Close < 10) {
                    return am5.color("#0a8f0b");
                  } else if (candle.dataContext.Close > 90) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#BFC3CA");
                  }
                }
              }
            );

            // Customize the body color based on your condition
            series.columns.template.adapters.add(
              "fill",
              (fill: any, target: any) => {
                let dataItem: any = target.dataItem;
                if (pcpName === "PCP") {
                  if (dataItem?.dataContext.Close > 90) {
                    return am5.color("#182F28");
                  } else if (
                    dataItem?.dataContext.Close > 80 &&
                    dataItem?.dataContext.Close < 90
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    dataItem?.dataContext.Close > 75 &&
                    dataItem?.dataContext.Close < 80
                  ) {
                    return am5.color("#7A0000");
                  } else if (
                    dataItem?.dataContext.Close > 10 &&
                    dataItem?.dataContext.Close < 20
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    dataItem?.dataContext.Close > 5 &&
                    dataItem?.dataContext.Close < 10
                  ) {
                    return am5.color("#005F33");
                  } else if (dataItem?.dataContext.Close < 5) {
                    return am5.color("#182F28");
                  }
                  return am5.color("#F7F8FF");
                } else if (pcpName === "PCP100" && timeRange === "day") {
                  if (dataItem?.dataContext.Close > 18250) {
                    return am5.color("#182F28");
                  } else if (
                    dataItem?.dataContext.Close > 18000 &&
                    dataItem?.dataContext.Close < 18250
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    dataItem?.dataContext.Close > 17750 &&
                    dataItem?.dataContext.Close < 18000
                  ) {
                    return am5.color("#7A0000");
                  } else if (
                    dataItem?.dataContext.Close > 15750 &&
                    dataItem?.dataContext.Close < 16000
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    dataItem?.dataContext.Close > 15500 &&
                    dataItem?.dataContext.Close < 15750
                  ) {
                    return am5.color("#005F33");
                  } else if (dataItem?.dataContext.Close < 15500) {
                    return am5.color("#182F28");
                  }
                  return am5.color("#F7F8FF");
                } else if (pcpName === "PCP100" && timeRange === "week") {
                  if (dataItem?.dataContext.Close > 18000) {
                    return am5.color("#182F28");
                  } else if (
                    dataItem?.dataContext.Close > 17000 &&
                    dataItem?.dataContext.Close < 18000
                  ) {
                    return am5.color("#5E0208");
                  } else if (
                    dataItem?.dataContext.Close > 16000 &&
                    dataItem?.dataContext.Close < 17000
                  ) {
                    return am5.color("#7A0000");
                  } else if (
                    dataItem?.dataContext.Close > 7000 &&
                    dataItem?.dataContext.Close < 9000
                  ) {
                    return am5.color("#0a8f0b");
                  } else if (
                    dataItem?.dataContext.Close > 6000 &&
                    dataItem?.dataContext.Close < 7000
                  ) {
                    return am5.color("#005F33");
                  } else if (dataItem?.dataContext.Close < 6000) {
                    return am5.color("#182F28");
                  }
                  return am5.color("#F7F8FF");
                } else if (pcpName === "PCP103") {
                  if (dataItem?.dataContext.Close < 40) {
                    return am5.color("#0a8f0b");
                  } else if (dataItem?.dataContext.Close > 60) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#F7F8FF");
                  }
                } else if (pcpName === "PCP107") {
                  //PCP107
                  if (dataItem?.dataContext.Close < 25) {
                    return am5.color("#0a8f0b");
                  } else if (dataItem?.dataContext.Close > 60) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#F7F8FF");
                  }
                } else if (pcpName === "PCP106") {
                  //PCP106
                  if (dataItem?.dataContext.Close < -250) {
                    return am5.color("#0a8f0b");
                  } else if (dataItem?.dataContext.Close > 250) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#F7F8FF");
                  }
                } else if (pcpName === "PCP111") {
                  //PCP111
                  if (dataItem?.dataContext.Close < 5) {
                    return am5.color("#0a8f0b");
                  } else if (dataItem?.dataContext.Close > 40) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#F7F8FF");
                  }
                } else if (pcpName === "PCP112") {
                  //PCP112
                  if (dataItem?.dataContext.Close < 5) {
                    return am5.color("#0a8f0b");
                  } else if (dataItem?.dataContext.Close > 40) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#F7F8FF");
                  }
                } else if (pcpName === "PCP122") {
                  if (dataItem?.dataContext.Close < 10) {
                    return am5.color("#0a8f0b");
                  } else if (dataItem?.dataContext.Close > 90) {
                    return am5.color("#7A0000");
                  } else {
                    return am5.color("#F7F8FF");
                  }
                }
              }
            );
          }
          break;
        case "ohlc":
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.OHLCSeries.new(root, {
              name: pcpName,
              clustered: false,
              valueXField: "Date",
              valueYField: "Close",
              highValueYField: "High",
              lowValueYField: "Low",
              openValueYField: "Open",
              calculateAggregates: true,
              xAxis: dateAxis,
              yAxis: valueAxis2,
              legendValueText:
                "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
              legendRangeValueText: "",
              sequencedInterpolation: true,
            })
          );
          series.columns.template.setAll({
            strokeWidth: 2,
          });

          // Customize the  wick color based on your condition
          series.columns.template.adapters.add(
            "stroke",
            (fill: any, target: any) => {
              const candle: any = target.dataItem;
              if (pcpName === "PCP112") {
                if (candle.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP") {
                if (candle.dataContext.High >= 90) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 80 &&
                  candle.dataContext.High < 90
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.High >= 75 &&
                  candle.dataContext.High < 79.99
                ) {
                  return am5.color("#7A0000");
                } else if (
                  candle.dataContext.High >= 90 &&
                  candle.dataContext.Close > 80
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 80 &&
                  candle.dataContext.Close > 75
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.Low > 10 &&
                  candle.dataContext.Close < 20
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low > 5 &&
                  candle.dataContext.Close < 10
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.Low < 20 &&
                  candle.dataContext.Low > 10
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low < 10 &&
                  candle.dataContext.Low > 5
                ) {
                  return am5.color("#005F33");
                } else if (candle.dataContext.Low < 5) {
                  return am5.color("#182F28");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP100" && timeRange === "day") {
                if (candle.dataContext.High >= 18250) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 18000 &&
                  candle.dataContext.High < 18250
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.High >= 17500 &&
                  candle.dataContext.High < 17999.99
                ) {
                  return am5.color("#7A0000");
                } else if (
                  candle.dataContext.High >= 18250 &&
                  candle.dataContext.Close > 18000
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 17750 &&
                  candle.dataContext.Close > 18000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.Low > 15750 &&
                  candle.dataContext.Close < 16000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low > 15500 &&
                  candle.dataContext.Close < 15750
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.Low < 16000 &&
                  candle.dataContext.Low > 15750
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low < 15750 &&
                  candle.dataContext.Low > 15500
                ) {
                  return am5.color("#005F33");
                } else if (candle.dataContext.Low < 15500) {
                  return am5.color("#182F28");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP100" && timeRange === "week") {
                if (candle.dataContext.High >= 18000) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 17000 &&
                  candle.dataContext.High < 18000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.High >= 16000 &&
                  candle.dataContext.High < 16999.99
                ) {
                  return am5.color("#7A0000");
                } else if (
                  candle.dataContext.High >= 18000 &&
                  candle.dataContext.Close > 17000
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.High >= 16000 &&
                  candle.dataContext.Close > 17000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  candle.dataContext.Low > 7000 &&
                  candle.dataContext.Close < 9000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low > 6000 &&
                  candle.dataContext.Close < 7000
                ) {
                  return am5.color("#182F28");
                } else if (
                  candle.dataContext.Low < 9000 &&
                  candle.dataContext.Low > 7000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  candle.dataContext.Low < 7000 &&
                  candle.dataContext.Low > 6000
                ) {
                  return am5.color("#005F33");
                } else if (candle.dataContext.Low < 6000) {
                  return am5.color("#182F28");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP103") {
                if (candle.dataContext.Close < 40) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP106") {
                //pcp106
                if (candle.dataContext.Close < -250) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 250) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP107") {
                if (candle.dataContext.Close < 25) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP111") {
                if (candle.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              } else if (pcpName === "PCP122") {
                if (candle.dataContext.Close < 10) {
                  return am5.color("#0a8f0b");
                } else if (candle.dataContext.Close > 90) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#BFC3CA");
                }
              }
            }
          );

          // Customize the body color based on your condition
          series.columns.template.adapters.add(
            "fill",
            (fill: any, target: any) => {
              let dataItem: any = target.dataItem;
              if (pcpName === "PCP") {
                if (dataItem?.dataContext.Close > 90) {
                  return am5.color("#182F28");
                } else if (
                  dataItem?.dataContext.Close > 80 &&
                  dataItem?.dataContext.Close < 90
                ) {
                  return am5.color("#5E0208");
                } else if (
                  dataItem?.dataContext.Close > 75 &&
                  dataItem?.dataContext.Close < 80
                ) {
                  return am5.color("#7A0000");
                } else if (
                  dataItem?.dataContext.Close > 10 &&
                  dataItem?.dataContext.Close < 20
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  dataItem?.dataContext.Close > 5 &&
                  dataItem?.dataContext.Close < 10
                ) {
                  return am5.color("#005F33");
                } else if (dataItem?.dataContext.Close < 5) {
                  return am5.color("#182F28");
                }
                return am5.color("#F7F8FF");
              } else if (pcpName === "PCP100" && timeRange === "day") {
                if (dataItem?.dataContext.Close > 18250) {
                  return am5.color("#182F28");
                } else if (
                  dataItem?.dataContext.Close > 18000 &&
                  dataItem?.dataContext.Close < 18250
                ) {
                  return am5.color("#5E0208");
                } else if (
                  dataItem?.dataContext.Close > 17750 &&
                  dataItem?.dataContext.Close < 18000
                ) {
                  return am5.color("#7A0000");
                } else if (
                  dataItem?.dataContext.Close > 15750 &&
                  dataItem?.dataContext.Close < 16000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  dataItem?.dataContext.Close > 15500 &&
                  dataItem?.dataContext.Close < 15750
                ) {
                  return am5.color("#005F33");
                } else if (dataItem?.dataContext.Close < 15500) {
                  return am5.color("#182F28");
                }
                return am5.color("#F7F8FF");
              } else if (pcpName === "PCP100" && timeRange === "week") {
                if (dataItem?.dataContext.Close > 18000) {
                  return am5.color("#182F28");
                } else if (
                  dataItem?.dataContext.Close > 17000 &&
                  dataItem?.dataContext.Close < 18000
                ) {
                  return am5.color("#5E0208");
                } else if (
                  dataItem?.dataContext.Close > 16000 &&
                  dataItem?.dataContext.Close < 17000
                ) {
                  return am5.color("#7A0000");
                } else if (
                  dataItem?.dataContext.Close > 7000 &&
                  dataItem?.dataContext.Close < 9000
                ) {
                  return am5.color("#0a8f0b");
                } else if (
                  dataItem?.dataContext.Close > 6000 &&
                  dataItem?.dataContext.Close < 7000
                ) {
                  return am5.color("#005F33");
                } else if (dataItem?.dataContext.Close < 6000) {
                  return am5.color("#182F28");
                }
                return am5.color("#F7F8FF");
              } else if (pcpName === "PCP103") {
                if (dataItem?.dataContext.Close < 40) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP107") {
                //PCP107
                if (dataItem?.dataContext.Close < 25) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 60) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP106") {
                //PCP106
                if (dataItem?.dataContext.Close < -250) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 250) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP111") {
                //PCP111
                if (dataItem?.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP112") {
                //PCP112
                if (dataItem?.dataContext.Close < 5) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 40) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              } else if (pcpName === "PCP122") {
                if (dataItem?.dataContext.Close < 10) {
                  return am5.color("#0a8f0b");
                } else if (dataItem?.dataContext.Close > 90) {
                  return am5.color("#7A0000");
                } else {
                  return am5.color("#F7F8FF");
                }
              }
            }
          );
          break;
      }

      // Set new series as stockSeries
      if (series) {
        valueLegend.data.removeValue(currentSeries);
        series.data.setAll(data);
        stockChart2.set("stockSeries", series);
        let cursor = mainPanel.get("cursor");
        if (cursor) {
          cursor.set("snapToSeries", [series]);
        }
        valueLegend.data.insertIndex(1, series);
      }
    }

    //Time Range
    let periodSelector = am5stock.PeriodSelector.new(root, {
      stockChart: stockChart,
    });

    valueSeries.events.once("datavalidated", (event: any) => {
      let time: any = selectedPeriod;
      periodSelector.selectPeriod(time);
    });

    //time interval switcher
    var intervalSwitcher = am5stock.IntervalControl.new(root, {
      stockChart: stockChart,
      visible: false,
    });

    intervalSwitcher.events.on("selected", function (ev: any) {
      // Determine selected granularity
      //selectedPeriod
      let base: any = intervalControl.interval;

      // Get series
      var valueSeries = stockChart.get("stockSeries");

      // Set up zoomout
      valueSeries?.events.once("datavalidated", function () {
        mainPanel.zoomOut();
      });

      // Load data for all series (main series + comparisons)
      var promises = [];
      promises.push(loadData(symName, [valueSeries, sbSeries], timeRange));
      //promises.push(loadData(pcpName, [valueSeries2, sbSeries], timeRange));
      am5.array.each(
        stockChart.getPrivate("comparedSeries", []),
        function (series) {
          promises.push(loadData(series.get("name"), [series], timeRange));
        }
      );
      // Once data loading is done, set `baseInterval` on the DateAxis
      Promise.all(promises).then(function () {
        dateAxis.set("baseInterval", base);
      });
    });

    //indicators control panel
    const indicatorsControl = am5stock.IndicatorControl.new(root, {
      stockChart: stockChart,
      legend: valueLegend,
    });

    stockChart.events.on("indicatorsupdated", function (ev) {
      let d: any = indicatorsControl.serializeIndicators("object");
      setSaveIndicators(d);
    });
    let sI = JSON.stringify(saveIndicators);
    //console.log("ind--->",sI);
    if (pass !== null && passTime !== null) {
      indicatorsControl.unserializeIndicators(sI);
    }

    const drawingControl = am5stock.DrawingControl.new(root, {
      stockChart: stockChart,
      showExtension: true,
    });

    drawingControl.events.on("click", function (ev) {
      setDrawPosition(!drawPosition);
    });

    stockChart.events.on("drawingsupdated", function (ev) {
      const getDrawData: any = drawingControl.serializeDrawings("object");
      setSaveDraws(getDrawData);
    });

    let sD = JSON.stringify(saveDraws);
    if (pass !== null && passTime !== null) {
      drawingControl.unserializeDrawings(sD);
    }
    // Stock toolbar
    //define multi roots in toolbar
    let root2 = document.getElementById("chartcontrols")!;
    let root3 = document.getElementById("chartcontrols2")!;
    let root4 = document.getElementById("chartcontrols3")!;
    let root5 = document.getElementById("chartcontrols4")!;
    let root6 = document.getElementById("chartcontrols5")!;
    let root7 = document.getElementById("chartcontrols6")!;
    let root8 = document.getElementById("chartcontrols7")!;
    let v1 = showData === true ? root2 : !root2;
    if (v1) {
      if (root6) {
        //main tool
        am5stock.StockToolbar.new(root, {
          container: root6,
          stockChart: stockChart,
          controls: [mainSeriesControl],
        });
      }
      am5stock.StockToolbar.new(root, {
        container: root2,
        stockChart: stockChart,
        controls: [
          am5stock.PeriodSelector.new(root, {
            stockChart: stockChart,
            visible: false,
          }),
          intervalSwitcher,
          seriesSwitcher,
        ],
      });

      if (!root4) {
        //indicator action
        am5stock.StockToolbar.new(root, {
          container: root4,
          stockChart: stockChart,
          controls: [
            am5stock.IndicatorControl.new(root, {
              stockChart: stockChart,
              legend: valueLegend,
            }),
          ],
        });
      }

      if (root3) {
        //pcp switcher
        am5stock.StockToolbar.new(root, {
          container: root3,
          stockChart: stockChart,
          controls: [seriesSwitcher2],
        });
      }
      if (root7) {
        //pcp switcher
        am5stock.StockToolbar.new(root, {
          container: root7,
          stockChart: stockChart,
          controls: [mainSeriesControl2],
        });
      }
      if (root8) {
        am5stock.StockToolbar.new(root, {
          container: root8,
          stockChart: stockChart,
          controls: [indicatorsControl],
        });
      }

      if (root5) {
        //draw action
        am5stock.StockToolbar.new(root, {
          container: root5,
          stockChart: stockChart,
          controls: [drawingControl],
        });
      }
    }

    hljs.initHighlightingOnLoad(); //chart loading
    root?._logo?.dispose(); // disabled am5 chart logo


    return () => {
      root.dispose();
      stockChart.dispose();
      stockChart2.dispose();
      mainPanel.dispose();
      cursor.dispose();
      valueAxis.dispose();
      valueAxis2.dispose();
      dateAxis.dispose();
      valueSeries.dispose();
      valueSeries2.dispose();
      valueLegend.dispose();
      volumeAxisRenderer.dispose();
      volumeValueAxis.dispose();
      volumeValueAxis2.dispose();
      volumeSeries.dispose();
      volumeSeries2.dispose();
      volumeAxisRenderer2.dispose();
      scrollbar.dispose();
      sbDateAxis.dispose();
      sbValueAxis.dispose();
      sbSeries.dispose();
      mainSeriesControl.dispose();
      mainSeriesControl2.dispose();
      seriesSwitcher.dispose();
      seriesSwitcher2.dispose();
      periodSelector.dispose();
      intervalSwitcher.dispose();
      indicatorsControl.dispose();
      drawingControl.dispose();
      clearInterval(intervalId);
    };
  }, [
    visible,
    showData,
    symName,
    pcpName,
    selectedPeriod,
    intervalControl,
    timeRange,
    getDropValues,
    symSeriesId,
    pcpSeriesId,
    applyLayout,
  ]);

  //console.log("symbol---->",symData);
  const createLayout = () => {
    //Save Dashboard
    setIsLoading(true);
    let body = {
      mName: mdName,
      mData: {
        liveSymbolName: symName,
        pcpSynmolName: pcpName,
        saveSeriesTypeA: symSeriesId,
        saveSeriesTypeB: pcpSeriesId,
        saveInterval: selectedPeriod,
        savePeriod: intervalControl,
        saveIndicators: saveIndicators,
        saveDrawings: saveDraws,
        timeUnit: timeRange,
      },
    };
    //console.log("create--->",body)
    let createUrl = "https://ceiak6b9aa.execute-api.us-west-2.amazonaws.com/test/create";
    //let createUrl =
     // "https://1rd6bf770j.execute-api.us-west-2.amazonaws.com/test/create_dev";
    axios
      .post(createUrl, body)
      .then((res) => {
        //console.log("res",res);
        getDropCall();
        setMultiNameOpen(false);
        handleLoaderClose();
        setMdName("Historical");
        setToastOpen(true);
        setMsgStatus("saved");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        //console.log("finally")
      });
  };
  const handleGetData = (it: any) => {
    setIsLoading(true);
    let body = {
      id: it,
    };
    //console.log("get item body", body);
    let singleGetUrl = "https://ceiak6b9aa.execute-api.us-west-2.amazonaws.com/test/single_get";
    //let singleGetUrl =
    // "https://1rd6bf770j.execute-api.us-west-2.amazonaws.com/test/singleget_dev";
    axios
      .post(singleGetUrl, body)
      .then(async (res) => {
        let getRes = res.data?.data?.mData;
        setSymName(getRes?.liveSymbolName);
        setPcpName(getRes?.pcpSynmolName);
        setSaveIndicators(getRes?.saveIndicators);
        setSaveDraws(getRes?.saveDrawings);
        setIntervalControl({
          id: getRes?.savePeriod?.id,
          label: getRes?.savePeriod?.label,
          interval: {
            timeUnit: getRes?.savePeriod?.interval?.timeUnit,
            count: getRes?.savePeriod?.interval?.count,
          },
        });
        setSelectedPeriod({
          timeUnit: getRes?.saveInterval?.timeUnit,
          count: getRes?.saveInterval?.count,
          name: getRes?.saveInterval?.name,
        });
        setTimeRange(getRes?.timeUnit);
        setSymSeriesId({
          id: getRes?.saveSeriesTypeA?.id,
          label: getRes?.saveSeriesTypeA?.label,
          icon: getRes?.saveSeriesTypeA?.icon,
        });
        setPcpSeriesId({
          id: getRes?.saveSeriesTypeB?.id,
          label: getRes?.saveSeriesTypeB?.label,
          icon: getRes?.saveSeriesTypeB?.icon,
        });
        setDropValues({
          id: res.data?.data?.id,
          mName: res.data?.data?.mName,
        });
        const initialDat = {
          id: res.data?.data?.id,
          mName: res.data?.data?.mName,
          status: 1,
          mData: {
            liveSymbolName: getRes?.liveSymbolName,
            pcpSymbolName: getRes?.pcpSynmolName,
            saveSeriesTypeA: {
              id: getRes?.saveSeriesTypeA?.id,
              label: getRes?.saveSeriesTypeA?.label,
              icon: getRes?.saveSeriesTypeA?.icon,
            },
            saveSeriesTypeB: {
              id: getRes?.saveSeriesTypeB?.id,
              label: getRes?.saveSeriesTypeB?.label,
              icon: getRes?.saveSeriesTypeB?.icon,
            },
            timeUnit: getRes?.timeUnit,
            saveInterval: {
              timeUnit: getRes?.saveInterval?.timeUnit,
              count: getRes?.saveInterval?.count,
              name: getRes?.saveInterval?.name,
            },
            savePeriod: {
              id: getRes?.savePeriod?.id,
              label: getRes?.savePeriod?.label,
              interval: {
                timeUnit: getRes?.savePeriod?.interval?.timeUnit,
                count: getRes?.savePeriod?.interval?.count,
              },
            },
            saveIndicators: getRes?.saveIndicators,
            saveDrawings: getRes?.saveDrawings,
          },
        };
        localStorage.setItem("update_data", JSON.stringify(initialDat));
        handleLoaderClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        //console.log("finally")
      });
  };
  const handleUpdateData = () => {
    setIsLoading(true);
    let body = {
      id: getDropValues.id,
      mName: mdName === "" ? getDropValues.mName : mdName,
      mData: {
        liveSymbolName: symName,
        pcpSynmolName: pcpName,
        saveSeriesTypeA: symSeriesId,
        saveSeriesTypeB: pcpSeriesId,
        saveInterval: selectedPeriod,
        savePeriod: intervalControl,
        saveIndicators: saveIndicators,
        saveDrawings: saveDraws,
        timeUnit: timeRange,
      },
    };
    //console.log("update body", body);
   let updateUrl = "https://ceiak6b9aa.execute-api.us-west-2.amazonaws.com/test/update";
   // let updateUrl =
    // "https://1rd6bf770j.execute-api.us-west-2.amazonaws.com/test/update_dev";
    axios
      .post(updateUrl, body)
      .then((res) => {
        let getRes = res.data?.data;
        //console.log("update res", res.data)
        getDropCall();
        handleGetData(getRes?.id);
        setMultiNameOpen(false);
        setToastOpen(true);
        setMsgStatus("updated");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        //console.log("finally")
      });
  };

  const handleDelteData = (it: any) => {
    setIsLoading(true);
    let body = {
      id: it,
    };
    //console.log("delete",it)
    let deleteUrl = "https://ceiak6b9aa.execute-api.us-west-2.amazonaws.com/test/deactivate";
    //let deleteUrl =
    // "https://1rd6bf770j.execute-api.us-west-2.amazonaws.com/test/deactivate_dev";
    axios
      .post(deleteUrl, body)
      .then((res) => {
        //console.log("res", res.data);
        if (getDropValues.id === it) {
          localStorage.removeItem("update_data");
          let getRes = initialData?.mData;
          setSymName(getRes?.liveSymbolName);
          setPcpName(getRes?.pcpSymbolName);
          setSaveIndicators(getRes?.saveIndicators);
          setSaveDraws(getRes?.saveDrawings);
          setIntervalControl({
            id: getRes?.savePeriod?.id,
            label: getRes?.savePeriod?.label,
            interval: {
              timeUnit: getRes?.savePeriod?.interval?.timeUnit,
              count: getRes?.savePeriod?.interval?.count,
            },
          });
          setSelectedPeriod({
            timeUnit: getRes?.saveInterval?.timeUnit,
            count: getRes?.saveInterval?.count,
            name: getRes?.saveInterval?.name,
          });
          setTimeRange(getRes?.timeUnit);
          setSymSeriesId({
            id: "line",
            label: "Line",
            icon: "Line Series",
          });
          setPcpSeriesId({
            id: "candlestick",
            label: "Candles",
            icon: "Candlestick Series",
          });
          setDropValues({
            id: 0,
            mName: "Historical",
          });
          setMdName("Historical");
          setApplyLayout({
            id: 0,
            mName: "Historical",
          });
          getDropCall();
          handleLoaderClose();
          setToastOpen(true);
          setMsgStatus("deleted");
        } else {
          getDropCall();
          handleLoaderClose();
          setToastOpen(true);
          setMsgStatus("deleted");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        //console.log("finally")
      });
  };

  const handleLoaderClose = () => {
    setIsLoading(false);
  };

  const handleDrawClick = (ev: any) => {
    //console.log(ev, '--->handleDrawevent');
    //setIsDrawActive(!isDrawActive);

    const eraserDiv: any = document.querySelector('div[title="Eraser"]');

    const existingLabelDiv = eraserDiv.querySelector(".am5stock-control-label");
    if (existingLabelDiv) {
      eraserDiv.removeChild(existingLabelDiv);
    }

    // Create a new label div
    const newLabelDiv = document.createElement("div");
    newLabelDiv.classList.add("am5stock-control-label");
    newLabelDiv.textContent = "Eraser";

    // Append the new label div to the eraser div
    eraserDiv.appendChild(newLabelDiv);

    const pathElement: any = eraserDiv.querySelector("path");
    //console.log(pathElement, '--->pathElement');

    pathElement.classList.add("eraserIconColor");
  };

  return (
    <>
      <div>
        <div>
          {/*Dialog model ui start*/}
          <div>
            <Dialog open={open}>
              <DialogTitle>Password required</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This site is password protected. Please enter a password.
                </DialogContentText>
                <TextField
                  margin="dense"
                  id="password"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={password}
                  onChange={(e) => {
                    e.preventDefault();
                    setPassword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      document.getElementById("myBtn")?.click();
                    }
                  }}
                />
                {visible === "empty" ? (
                  <Typography color={"red"} fontSize={14}>
                    Password required*
                  </Typography>
                ) : null}
                {visible === "wrong" ? (
                  <Typography color={"red"} fontSize={14}>
                    Please enter correct password
                  </Typography>
                ) : null}
              </DialogContent>
              <DialogActions>
                <Button id="myBtn" onClick={handleClose} variant="contained">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/*end*/}
          {/*series switcher and stock toolbar ui code start*/}
          <div>
            <div style={{ marginLeft: "15px", position: "relative" }}>
              <div
                style={{ position: "absolute", width: "100%", display: "flex" }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container spacing={0}>
                        <div
                          style={{
                            marginLeft: symName.length === 4 ? 87 : 75,
                            marginTop: 2,
                            marginRight: 1,
                          }}
                        >
                          <div
                            style={{
                              height: "30px",
                              border:
                                showData === true
                                  ? "1px solid #CBCBCB"
                                  : "1px solid #FFFF",
                              borderRadius: "4px",
                              display: "flex",
                              marginRight: 1,
                            }}
                          >
                            {showData === true ? (
                              <div
                                style={{
                                  fontSize: "14px",
                                  marginLeft: 3,
                                  marginRight: 3,
                                  marginTop: 5,
                                }}
                              >
                                {symName} Chart :
                              </div>
                            ) : null}

                            <div
                              id="chartcontrols"
                              style={{ marginTop: -2.5, marginRight: -2 }}
                            ></div>
                          </div>
                        </div>

                        <div
                          id="chartcontrols3"
                          className="indicator"
                          style={{ marginTop: 0, marginRight: -2 }}
                        ></div>
                        <div
                          style={{
                            marginTop: 2,
                            marginLeft: 2,
                            display: "flex",
                          }}
                          // className="Pcpchart"
                        >
                          <div
                            style={{
                              height: "30px",
                              border:
                                showData === true
                                  ? "1px solid #CBCBCB"
                                  : "1px solid #FFFF",
                              borderRadius: "4px",
                              display: "flex",
                            }}
                          >
                            {showData === true ? (
                              <div
                                style={{
                                  fontSize: "14px",
                                  marginLeft: 3,
                                  marginRight: 3,
                                  marginTop: 5,
                                }}
                              >
                                PCP Chart :
                              </div>
                            ) : null}
                            <div
                              id="chartcontrols2"
                              style={{ marginTop: -2.5, marginRight: -2 }}
                            ></div>
                          </div>
                          <div
                            id="chartcontrols6"
                            style={{ marginTop: -2.5, marginRight: -2 }}
                          ></div>
                        </div>
                        {/* <Collapse in={!isCollapsed}> */}
                        {showData === true ? (
                          <div className="selectbox">
                            <div style={{}}>
                              <FormControl focused={false}>
                                <Select
                                  id="periodSelector"
                                  onChange={handleSelectChange}
                                  value={JSON.stringify(selectedPeriod)}
                                  className="periodSelectorr"
                                  style={{
                                    borderWidth: 0.1,
                                  }}
                                  startAdornment={
                                    <ListItemIcon
                                      style={{
                                        minWidth: "5px",
                                        marginLeft: "-5px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <AccessTimeIcon fontSize="small" />
                                    </ListItemIcon>
                                  }
                                >
                                  {period.map((period) => (
                                    <MenuItem
                                      key={period.name}
                                      value={JSON.stringify(period)}
                                    >
                                      {period.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            <div style={{ marginLeft: -4 }}>
                              <FormControl focused={false}>
                                <Select
                                  id="intervalControler"
                                  onChange={handleSelectTimeRange}
                                  value={JSON.stringify(intervalControl)}
                                  className="periodSelectorr"
                                  style={{
                                    borderWidth: 0.1,
                                  }}
                                  startAdornment={
                                    <ListItemIcon
                                      style={{
                                        minWidth: "5px",
                                        marginLeft: "-5px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <EventIcon fontSize="small" />
                                    </ListItemIcon>
                                  }
                                >
                                  {range.map((item) => (
                                    <MenuItem
                                      key={item.id}
                                      value={JSON.stringify(item)}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        ) : null}

                        <div id="chartcontrols7" style={{}}></div>

                        <div
                          id="chartcontrols4"
                          style={{ display: "flex" }}
                          onClick={handleDrawClick}
                        ></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginLeft: "auto" }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container spacing={0}>
                        <div>
                          {showData === true && (
                            <div style={{ display: "flex", marginRight: 15 }}>
                              <div>
                                <Tooltip title={getDropValues.mName}>
                                  <Button
                                    id="demo-customized-button"
                                    aria-controls={
                                      dropOpen
                                        ? "demo-customized-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      dropOpen ? "true" : undefined
                                    }
                                    variant="outlined"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={
                                      dropOpen ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      color: "#000",
                                      height: 31,
                                      marginTop: 2,
                                      width: 150,
                                      textTransform: "none",
                                    }}
                                  >
                                    {getDropValues.mName
                                      .split(" ")
                                      .slice(0, 2)
                                      .join(" ")}
                                  </Button>
                                </Tooltip>
                              </div>
                              <Menu
                                id="lock-menu"
                                anchorEl={anchorEl}
                                open={dropOpen}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "lock-button",
                                  role: "listbox",
                                }}
                              >
                                <div>
                                  <Typography
                                    color={"gray"}
                                    style={{ marginLeft: 10 }}
                                  >
                                    Saved Dashboards
                                  </Typography>
                                  <Divider />
                                </div>

                                {getNames.length > 0 &&
                                  getNames.map((option) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          marginLeft: 5,
                                          marginRight: 10,
                                          justifyContent: "space-between",
                                        }}
                                        key={option.id}
                                      >
                                        <>
                                        <Tooltip arrow placement="left-start" title={option.mName?.length > 19 && option.mName}>
                                        <div
                                          style={{ width: 200 }}
                                          onClick={() => {
                                            localStorage.removeItem(
                                              "update_data"
                                            );
                                            handleGetData(option.id);
                                            setDropValues({
                                              id: option.id,
                                              mName: option.mName,
                                            });
                                            setMdName(option.mName);
                                            handleMenuClose();
                                          }}
                                        >
                                          <MenuItem>
                                            <Typography
                                              style={{
                                                maxWidth: 200,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                color:
                                                  option.mName ===
                                                  getDropValues.mName
                                                    ? "green"
                                                    : "#000",
                                              }}
                                            >
                                              {option.mName}
                                            </Typography>
                                          </MenuItem>
                                        </div>
                                        </Tooltip>
                                        </>
                                        <div
                                          onClick={() => {
                                            handleDelteData(option?.id);
                                            handleMenuClose();
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{ fontSize: 27 }}
                                            style={{
                                              color: "#ff3333",
                                              marginLeft: 5,
                                              marginTop: 3,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                <Divider />

                                <div>
                                  <Button
                                    href="/"
                                    variant="outlined"
                                    style={{
                                      marginTop: 2,
                                      border: "1px solid #fff",
                                      color: "#000",
                                      //width: 300,
                                      alignSelf: "center",
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                    onClick={() =>
                                      localStorage.removeItem("update_data")
                                    }
                                  >
                                    <AddIcon style={{ fontSize: 20 }} /> New
                                    Dashboard
                                  </Button>
                                </div>
                              </Menu>
                              {/* <div style={{}}>
                                {getDropValues.id !== 0 && (
                                  <div
                                    style={{
                                      border: "1px solid #3c7752",
                                      color: "#000",
                                      height: 27,
                                      borderRadius: 5,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginTop: 3,
                                      marginLeft: 3,
                                    }}
                                    onClick={handleUpdateData}
                                  >
                                    <SaveAsIcon
                                      sx={{ fontSize: 27 }}
                                      style={{ color: "#3c7752" }}
                                    />
                                  </div>
                                )}
                              </div> */}
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div id="chartcontrols5" style={{}}>
                {" "}
              </div>
            </div>

            {/*end*/}
            {/*main stock chart component ui div start*/}

            <div
              id="chartdiv"
              className={isDrawActive ? "stockchart add-margin" : "stockchart"}
              style={{
                width: "100%",
                height: "650px",
                maxWidth: "100%",
                marginTop: 10,
              }}
            ></div>

            {showData === true && (
              <div>
                <div
                  style={{
                    position: "absolute",
                    right: "106px",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    onClick={handleSaveData}
                    variant="outlined"
                    color="success"
                    style={{
                      padding: "2.5px",
                      marginTop: "2px",
                      marginLeft: "2px",
                    }}
                  >
                    {getDropValues.id === 0 ? "Save Data" : "Update Data"}
                  </Button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "20px",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    href="/addData"
                    variant="outlined"
                    color="success"
                    style={{
                      padding: "2.5px",
                      marginTop: "2px",
                      marginLeft: "2px",
                    }}
                  >
                    Add Data
                  </Button>
                </div>
              </div>
            )}
            <Dialog
              onClose={handleMultiClose}
              aria-labelledby="customized-dialog-title"
              open={multiNameOpen}
              maxWidth="sm"
              fullWidth={true}
            >
              <div style={{ height: 35 }}>
                <DialogTitle id="customized-dialog-title">
                  {getDropValues.id === 0
                    ? "Create Chart Dashboard"
                    : "Update Chart Dashboard"}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleMultiClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#000",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider component="li" />
              <DialogContent>
                <DialogContentText color={"GrayText"}>
                  Enter a new chart dashboard name:
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="mdName"
                  name="Historical"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={getDropValues?.id === 0 ? mdName : mdName}
                  error={!isValid} // Set error state based on validation
                  helperText={!isValid && "Name is required"} // Show helper text if validation fails
                  onChange={(e) => {
                    e.preventDefault();
                    setMdName(e.target.value);
                    setIsValid(true);
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleMultiClose}>
                  Cancel
                </Button>
                <Button
                  autoFocus
                  type="submit"
                  variant="outlined"
                  color="success"
                  onClick={
                    getDropValues?.id === 0 ? createLayout : handleUpdateData
                  }
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            {/*end*/}
            <Snackbar
              open={toastOpen}
              autoHideDuration={1000}
              onClose={handleToastClose}
            >
              <Alert
                onClose={handleToastClose}
                severity="success"
                color={
                  msgStatus === "saved"
                    ? "success"
                    : msgStatus === "updated"
                    ? "success"
                    : "error"
                }
                variant="filled"
                sx={{ width: "100%" }}
              >
                {msgStatus === "saved"
                  ? "saved successfully!"
                  : msgStatus === "updated"
                  ? "Updated successfully!"
                  : "Deleted successfully!"}
              </Alert>
            </Snackbar>
          </div>
        </div>
        <Dialog open={isLoading} onClose={handleLoaderClose}>
          <Backdrop open={isLoading} onClick={handleLoaderClose}>
            <CircularProgress size={50} style={{ color: "#fff" }} />
          </Backdrop>
        </Dialog>
      </div>
    </>
  );
};
export default StockChartIndicator;
